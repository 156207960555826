import { useRef, useState, useEffect, useCallback, useContext } from "react";
import { Message_data } from "../context/context";
import { auth, db, db2, storage } from "../firebase-config";
import {
  collection,
  onSnapshot,
  query,
  doc,
  setDoc,
  getDocs,
  addDoc,
  deleteDoc,
  where,
} from "firebase/firestore";
import Player from "../components/AudioMusicPlayer/Player";
import axios from "axios";
import X2JS from "x2js";

export const NLMFileReader = ({ handleFile, user, playlistId, currentPlaylist }) => {
  const { message, setMessage } = useContext(Message_data);
  const [jukebox, setJukebox] = useState([]);
  const [trackList, setTrackList] = useState([]);
  const [NMLFile, setNMLFile] = useState("");
  const [showLoadingAni, setShowLoadingAni] = useState(true);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [updateLocalPlaylist, setUpdateLocalPlaylist] = useState([]);
  const [reconnect, setReconnect] = useState(false);

  useEffect(() => {
    const playerControlData = async () => {
      if (message) {
        const data = JSON.parse(message);
        if (data.loadingAni) {
          const loadingAni = JSON.parse(data.loadingAni);
          // console.log("loadingAni", loadingAni);
          setTimeout(() => {
            setShowLoadingAni(loadingAni);
          }, 2000);
        }
        //
        if (data.loadedPlaylist) {
          // acknowledge that the playlist has reloaded
          setShowLoadingAni(false)
        }
      }
    };
    playerControlData();
  }, [message]);

  let songs = document.getElementById("songs");

  // Create a reference to the hidden file input element
  const hiddenFileInput = useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleReconnect = (event) => {
    setReconnect(true);
    hiddenFileInput.current.click();
  };

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = async (e) => {
    e.preventDefault();

    let parsed2;
    let nmlgenre = {};
    const playlist = [];
    for (let i = 0; i < songs.files.length; i++) {
      if (songs.files.item(i).name.split(".")[1] === "nml") {
        setNMLFile(songs.files.item(i).name);
        const reader = new FileReader();

        // eslint-disable-next-line no-loop-func
        reader.onload = async (e) => {
          const file = e.target.result;

          let x2js = new X2JS();
          parsed2 = x2js.xml2js(file.toString()).NML.COLLECTION.ENTRY;
          parsed2.forEach((nml, i) => {
            nmlgenre[nml.LOCATION._FILE] = nml.INFO._GENRE;
          });
        };

        reader.readAsText(songs.files.item(i));
      }
    }

    setShowLoadingAni(true);

    // eslint-disable-next-line no-sequences
    let ebz = 0
    // console.log('playlistId',playlistId);
    // console.log('currentPlaylist', currentPlaylist);
    for (let i = 0; i < songs.files.length; i++) {
      if (songs.files.item(i).name.split(".")[1] !== "nml") {
        let url = URL.createObjectURL(songs.files.item(i)); //songs.files.item(i) //URL.createObjectURL(songs.files.item(i))
        let artist = songs.files.item(i).name.split(".")[0].split(" - ")[0];
        let track = songs.files.item(i).name.split(".")[0].split(" - ")[1];
        let filename = songs.files.item(i).name;

        // check if this track is already in the firestore 'tracks' collection
        // it comes with a an id and probably already got cover art so skip getting another cover art url
        // might even have a youtube id who knows?
        // should search for the filename specifically
        //  if found insert it into trackObj otherwise insert this new stuff

        // eslint-disable-next-line no-loop-func

        // search for filename in 'tracks' collection
        // if filename found this means the track already exists
        // update the record in 'tracks' and also the corresponding record in 'playlists'
        // if its not found in 'tracks' create a new record and also update the corresponding
        // playlists collection with this new record
        // use promises

        // deleteTracks(filename);

        if (reconnect) {

          console.log('reconnect');

          let updatePlaylist = new Promise(function (resolve, reject) {
            resolve("Promise resolved");
          });

          updatePlaylist
            // eslint-disable-next-line no-loop-func
            .then(async () => {
              const searchObj = await searchFilename(filename);
              console.log('searchObj', searchObj);
              if (searchObj) {
                ebz++;
                console.log('found searchObj', searchObj);
                console.log('found',ebz)
                searchObj.url = url;
                searchObj.genre = nmlgenre[`${filename}`];
                return searchObj;
              } else {
                console.log("new track...");
                let trackObj = {
                  artist: artist || "",
                  track: track || "",
                  url: url, // never need to save a blob url as it is not permananent. mp3 url are from the usb/hd local folder
                  filename: filename,
                  genre: nmlgenre[`${filename}`] || "",
                  coverart: await getCoverart(artist + " " + track),
                  youTubeId: "",
                };
                // save this track since we didn't find its filename
                const newTrack = await saveTrack(trackObj);
                trackObj.id = newTrack.id;
                await timeout(6500); // await timeout(Math.random() * 4000 + 1200);
                return trackObj;
              }
            })
            // eslint-disable-next-line no-loop-func
            .then((item) => {
              console.log("item", item);
              playlist.push(item);
              const udlpl = updateLocalPlaylist;

              //is this item in the original playlist. if not, ignore so it doesn't go back into the database
              const idx = currentPlaylist.findIndex(oitem => oitem.filename === item.filename);
              if(idx !== -1) {
                udlpl.push(item);
              }
              
              setUpdateLocalPlaylist(udlpl);

              if (i === songs.files.length - 1) {

                setTimeout(() => {
                  updateLocalPlaylist.forEach(async (item, i) => {
                    await setDoc(
                      doc(db, "playlists", playlistId.id, "tracks", item.id),
                      {
                        ...item,
                        genre: item.genre || "",
                        ordinal: i,
                      }
                    );
                  });

                  // setShowLoadingAni(false);
                  forceUpdate();
                }, 5000);

              }
            });
        } else {
          console.log('upload the whole ting');
          let uploadPlaylist = new Promise(function (resolve, reject) {
            resolve("Promise resolved");
          });

          uploadPlaylist
            .then(async () => {
              const searchObj = await searchFilename(filename);
              if (searchObj) {
                searchObj.url = url;
                searchObj.genre = nmlgenre[`${filename}`];
                return searchObj;
              } else {
                console.log("new track...");
                let trackObj = {
                  artist: artist || "",
                  track: track || "",
                  url: url, // never need to save a blob url as it is not permananent. mp3 url are from the usb/hd local folder
                  filename: filename,
                  genre: nmlgenre[`${filename}`] || "",
                  coverart: await getCoverart(artist + " " + track),
                  youTubeId: "",
                };
                // save this track since we didn't find its filename
                const newTrack = await saveTrack(trackObj);
                trackObj.id = newTrack.id;
                await timeout(6500); // await timeout(Math.random() * 4000 + 1200);
                return trackObj;
              }
            })
            .then((item) => {
              // console.log("item", item);
              playlist.push(item);
              console.log('this needs to have filename in it', item)
              const udlpl = updateLocalPlaylist;
              udlpl.push(item);
              setUpdateLocalPlaylist(udlpl);

              if (i === songs.files.length - 1) {
                setTimeout(() => {
                  updateLocalPlaylist.forEach(async (item, i) => {
                    await setDoc(
                      doc(db, "playlists", playlistId.id, "tracks", item.id),
                      {
                        ...item,
                        genre: item.genre || "",
                        ordinal: i,
                      }
                    );
                  });

                  // setShowLoadingAni(false);
                  forceUpdate();
                }, 5000);
              }
            });
        }
      }
    }
  };

  const saveTrack = async (trackObj) => {
    const requestSongRef5 = collection(db, "tracks");
    try {
      const newprod = await addDoc(requestSongRef5, trackObj);
      return newprod;
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const deleteTracks = async (filename) => {
    const filenameRef = collection(db, "tracks");
    const queryFilename = query(filenameRef, where("filename", "==", filename)); //, where("createdAt", "<", serverTimestamp()), orderBy("createdAt"));

    onSnapshot(queryFilename, (snapshot) => {
      if (snapshot.docs && snapshot.docs[0]) {
        snapshot.forEach(async (docItem) => {
          const trackDocRef = doc(db, "tracks", snapshot.docs[0].id);
          await deleteDoc(trackDocRef);
        });
      } else {
        console.log("did not find this filename in database:", filename);
      }
    });
  };

  const searchFilename = async (filename) => {
    let fileList;

    try {
      const urlsRef = collection(db, "tracks");
      const q = query(urlsRef, where("filename", "==", filename));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((docSnap) => {
        fileList = {
          id: docSnap.id,
          track: docSnap.data().track,
          genre: docSnap.data().genre,
          artist: docSnap.data().artist,
          coverart: docSnap.data().coverart,
          filename: docSnap.data().filename,
          youTubeId: docSnap.data().youTubeId,
        };
      });

      return fileList; // Generate the returned object in the forEach loop, see link below
    } catch (e) {
      console.error("Error querying document: ", e);
      return e.response;
    }
  };

  const searchPlaylistFilename = async (filename) => {
    console.log('fileame:',filename);
    let fileList;

    try {
      const urlsRef = collection(db, "playlists", playlistId.id, "tracks");
      const q = query(urlsRef, where("filename", "==", filename));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((docSnap) => {
        console.log('docSnap.data().filename', docSnap.data().filename);
        fileList = {
          id: docSnap.id,
          track: docSnap.data().track,
          genre: docSnap.data().genre,
          artist: docSnap.data().artist,
          coverart: docSnap.data().coverart,
          filename: docSnap.data().filename,
          youTubeId: docSnap.data().youTubeId,
        };
      });

      console.log('fileList', fileList);

      return fileList; // Generate the returned object in the forEach loop, see link below
    } catch (e) {
      console.error("Error querying document: ", e);
      return e.response;
    }
  };

  const getCoverart = async (artisttrack) => {
    // first search for this track in the firehost database
    // if its there send back the track id it doesn't have acoverar then look for it below and send that back in the te obj
    try {
      // get covert with query
      const coverart = await axios.get(
        "https://getcoverart-n44tsg7wia-uc.a.run.app/?coverart=" + artisttrack
      );

      return await coverart.data.coverart.coverart;
    } catch (error) {
      console.log(error);
    }
  };

  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  return (
    <>
    <div className="flex flex-row">
    <button
        className="flex items-center justify-center  bg-red-500 hover:bg-red-600 rounded-xl text-white px-4 py-1  w-100 h-12 mt-4 mr-2 ml-2"
        onClick={handleClick}
      >
        <span>
          <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
            <title>Upload-3</title>
            <g
              id="Page-1"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g id="Upload-3">
                <rect
                  id="Rectangle"
                  fill-rule="nonzero"
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                ></rect>
                <line
                  x1="12"
                  y1="5"
                  x2="12"
                  y2="15"
                  id="Path"
                  stroke="#FFFFFF"
                  stroke-width="2"
                  stroke-linecap="round"
                ></line>
                <line
                  x1="19"
                  y1="20"
                  x2="5"
                  y2="20"
                  id="Path"
                  stroke="#FFFFFF"
                  stroke-width="2"
                  stroke-linecap="round"
                ></line>
                <path
                  d="M7,9 L11.2929,4.70711 C11.6834,4.31658 12.3166,4.31658 12.7071,4.70711 L17,9"
                  id="Path"
                  stroke="#FFFFFF"
                  stroke-width="2"
                  stroke-linecap="round"
                ></path>
              </g>
            </g>
          </svg>
        </span>
        <span className="text-sm font-black">UPLOAD MUUZBOX CRATE</span>
      </button>
      <button
        className="flex items-center justify-center  bg-green-500 hover:bg-green-600 rounded-xl text-white px-4 py-1  w-100 h-12 mt-4 mr-2 ml-2"
        onClick={handleReconnect}
      >
        <span>
          <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
            <title>Upload-3</title>
            <g
              id="Page-1"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g id="Upload-3">
                <rect
                  id="Rectangle"
                  fill-rule="nonzero"
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                ></rect>
                <line
                  x1="12"
                  y1="5"
                  x2="12"
                  y2="15"
                  id="Path"
                  stroke="#FFFFFF"
                  stroke-width="2"
                  stroke-linecap="round"
                ></line>
                <line
                  x1="19"
                  y1="20"
                  x2="5"
                  y2="20"
                  id="Path"
                  stroke="#FFFFFF"
                  stroke-width="2"
                  stroke-linecap="round"
                ></line>
                <path
                  d="M7,9 L11.2929,4.70711 C11.6834,4.31658 12.3166,4.31658 12.7071,4.70711 L17,9"
                  id="Path"
                  stroke="#FFFFFF"
                  stroke-width="2"
                  stroke-linecap="round"
                ></path>
              </g>
            </g>
          </svg>
        </span>
        <span className="text-sm font-black">RECONNECT MUUZBOX CRATE</span>
      </button>
    </div>
      <input
        type="file"
        onChange={(e) => handleChange(e)}
        ref={hiddenFileInput}
        style={{ display: "none" }} // Make the file input element invisible
        id="songs"
        name="fileList"
        webkitdirectory=""
      />
      {trackList.length > 0 && (
        <div className="bg-black">
          <Player jukebox={jukebox} trackList={trackList} />
        </div>
      )}
      {showLoadingAni && (
        <div className="bg-white divide-y divide-gray-100 rounded-lg shadow-lg w-44 dark:bg-gray-700 muuz-delete-confirm-panel mt-24 text-black">
          <div className="flex justify-between items-center text-black">
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div class="muuzbox-loader-logo-sm2 fadeIn" style={{ top: "200px" }}>
            <img src="img/muuzbox-logo.png" alt="Muuzbox logo" />
            <div class="muuzbox-loader-blob"></div>
          </div>
        </div>
      )}
    </>
  );
};

import React, { useState, useEffect, useContext } from "react";
import { Message_data } from "../context/context";
import { db, db2, auth } from "../firebase-config";
import { gapi } from "gapi-script";
import {
  doc,
  getDoc,
  addDoc,
  setDoc,
  deleteDoc,
  updateDoc,
  getDocs,
  collection,
  onSnapshot,
  query,
  serverTimestamp,
  where,
  orderBy,
  writeBatch,
} from "firebase/firestore";
import AccordionList from "./AccordionList";
import JukeboxRequests from "./Requests/JukeboxRequests";
import VideoPlayback4 from "./VideoPlayback4";
import Player from "./AudioMusicPlayer/Player";
// import Player2 from "./AudioMusicPlayer/Player2";
// import Player3 from "./AudioMusicPlayer/Player3";
import Player4 from "./AudioMusicPlayer/Player4";
import "../styles/Chat.scss";
import useVideos from "../hooks/useVideos";

export const AutoPlay = ({ userObj }) => {
  const [jukebox, setJukebox] = useState([]);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const userJukeboxRef = collection(
    db,
    "users",
    auth.currentUser.uid,
    "playlists"
  );
  const { message, setMessage } = useContext(Message_data);
  const [inputFocus, setInputFocus] = useState(false);
  const [originalJukebox, setOriginalJukebox] = useState([]);
  const [count, setCount] = useState(originalJukebox.length);
  const [isOpen, setIsOpen] = useState(false);
  const [displayNameOpen, setDisplayNameOpen] = useState(false);
  const [requestedTrack, setRequestedTrack] = useState({});
  const [DJChatId, setDJChatId] = useState("5fD03BaD7INyhJMne2gfLdPAKbQ2");
  const [messages, setMessages] = useState([]);
  const [avatars, setAvatars] = useState({});
  const [groupedTracks, setGroupedTracks] = useState([]);
  const [newDisplayName, setNewDisplayName] = useState("");
  const [jukeboxUserObj, setJukeboxUserObj] = useState(userObj);
  const [currentRequestDetails, setCurrentRequestDetails] = useState({});
  const [siteConfig, setSiteConfig] = useState({});
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [videos, search] = useVideos("react js custom hooks");
  const [draggingItem, setDraggingItem] = useState(null);
  const [showVideoPlayback, setShowVideoPlayback] = useState(false);
  const [showVideoPlayback2, setShowVideoPlayback2] = useState(false);
  const [showAudioPlayback, setShowAudioPlayback] = useState(false);

  const [trackId, setTrackId] = useState(null);
  const [showTrackForm, setShowTrackForm] = useState(false);
  const [genre, setGenre] = useState(null);
  const [artist, setArtist] = useState(null);
  const [track, setTrack] = useState(null);
  const [url, setUrl] = useState(null);
  const [youTubeId, setYouTubeId] = useState(null);
  const [currentTrack, setCurrentTrack] = useState({});
  const [coverart, setCoverart] = useState(null);
  const [videoId, setVideoId] = useState(null);
  const [currentPlayingTrackIndex, setCurrentPlayingTrackIndex] =
    useState(null);
  const [nextPlayingTrackIndex, setNextPlayingTrackIndex] = useState(null);
  const [currentDeck, setCurrentDeck] = useState(1);
  const [insertTrack, setInsertTrack] = useState(null);
  const [lastRequest, setLastRequest] = useState({});

  const [showMP3Playback, setShowMP3Playback] = useState(false);

  const [trackList, setTrackList] = useState([]);
  const [stopAudio, setStopAudio] = useState(false);
  const [playlistId, setPlaylistId] = useState(null);
  const [playlistName, setPlaylistName] = useState(null);
  const [currentSong, setCurrentSong] = useState(null);
  const [nextSong, setNextSong] = useState(null);
  const [previousCurrentSongId, setPreviousCurrentSongId] = useState(null);

  const [defaultPlaylist, setDefaultPlaylist] = useState("");
  const [accountName, setAccountName] = useState("");
  const [crossfadeDuration, setCrossfadeDuration] = useState("");
  const [jukeboxList, setJukeboxList] = useState([]);
  const [newPending, setNewPending] = useState([]);
  const [oldPending, setOldPending] = useState([]);

  // const calendarID = process.env.REACT_APP_CALENDAR_ID
  const apiKey = "AIzaSyAaGVGPCShsFPzcelLw5slKBV5Wa8FhYHc"; //process.env.REACT_APP_GOOGLE_API_KEY
  // const accessToken = process.env.REACT_APP_GOOGLE_ACCESS_TOKEN

  const getYoutubesx = (trackquery) => {
    gapi.client.setApiKey("AIzaSyAaGVGPCShsFPzcelLw5slKBV5Wa8FhYHc");
    gapi.client.load("youtube", "v3", function () {
      makeRequest(trackquery);
    });
  };

  const makeRequest = (q) => {
    let request = gapi.client.youtube.search.list({
      q: q,
      part: "snippet",
      maxResults: 10,
    });
    request.execute(function (response) {
      var srchItems = response.result.items;
      srchItems.forEach((item) => {
        // console.log("item", item);
        // vidTitle = item.snippet.title;
        // vidThumburl =  item.snippet.thumbnails.default.url;
        // vidURL = 'https://youtube.com/watch?v='+item.id.videoId;
        // vidThumbimg = '<pre><a href="'+vidURL+'"><img id="thumb" src="'+vidThumburl+'" alt="No  Image  Available." style="width:204px;height:128px"></a></pre>';
        // $('#results').append('<pre>' + vidTitle + vidThumbimg +   '</pre>');
      });
    });
  };

  useEffect(() => {
    const playerControlData = async () => {
      if (message) {
        const data = JSON.parse(message);
        if (data.insertTrack) {
          const insertTrack = JSON.parse(data.insertTrack);
          // compare ids not youTuneId local mp3s don't have youTubeIds....duh...!!!!
          // console.log("insertTrack", insertTrack);

          if (lastRequest.id !== insertTrack.id) {
            // insert it after current playing track
            // useing currentPlayingTrackIndex search along the jukebox array looking for the next item that is not queued from outside
            let queueSize = 1;
            const jukeboxSearchRange = jukebox.slice(
              currentPlayingTrackIndex + 1
            );
            const i = jukeboxSearchRange.findIndex(
              (item) => item.queued !== true
            );
            if (i !== -1) {
              queueSize = queueSize + i;
            }

            insertTrack.queued = true;
            insertTrack.ordinal = currentPlayingTrackIndex + queueSize;
            const p = jukebox;
            p.splice(currentPlayingTrackIndex + queueSize, 0, insertTrack);
            // get ride of queued tracks that have already been played
            let ii = 0;
            const removePending = [];
            while (ii < currentPlayingTrackIndex + 1) {
              p[ii].queued = false;
              //removePending.push(p[ii])
              //setOldPending(removePending); // remove from pending collection
              ii++;
            }
            console.log("update next track");
            console.log("queueSize", queueSize);
            console.log("jukebox", p);
            // console.log("messaged");
            // console.log("p", p);
            // creating pending from serching queued = true in p
            const pending = p.map((item) => {
              if (item.queued === true) return item;
            });
            setNewPending(pending);
            setJukebox(p);
            setLastRequest(insertTrack);
            forceUpdate();
          }
        }
        if (data.skipTrack) {
          // find this track in the jukebox list and mark it as skip.true
          const skipTrack = JSON.parse(data.skipTrack);
          console.log("*&* skippable default track", skipTrack);
          console.log("*&* jukebox", jukebox);
          const idx = jukebox.findIndex((item) => item.id === skipTrack.id);
          if (idx !== -1) {
            console.log("*&* marked this track to as skip:true");
            // at this point the next track should be immediately updated so its seen on the users screens
            const jb = jukebox;
            jb[idx].skip = true;
            // since this is now skipped the votes for it need to be purged
            setOldPending(jb[idx])
            setNextSong(jb[idx + 1]);
            setJukebox(jb);
            forceUpdate();
          }
        }
        if (data.delTrack) {
          // find this track in the jukebox list and delete it, as long as its it marked as a queued
          const delTrack = JSON.parse(data.delTrack);
          console.log("delTrack track", delTrack);
          const idx = jukebox.findIndex(
            (item) => item.id === delTrack.id && item.ordinal > 0
          );
          if (idx !== -1) {
            console.log("queued track to delete is", jukebox[idx]);
            const jb = jukebox;
            jb.splice(idx, 1);
            setJukebox(jb);
            forceUpdate();
          }
        }
      }
    };
    playerControlData();
  }, [
    currentPlayingTrackIndex,
    forceUpdate,
    jukebox,
    lastRequest.id,
    message,
    setMessage,
  ]);

  // useEffect(() => {

  //       const delSongRef = collection(
  //         db,
  //         "users",
  //         auth.currentUser.uid,
  //         "deleteRequest"
  //       );
  //       let ds = [];
  //       const unsubscribe = onSnapshot(delSongRef, (snapshot) => {
  //         snapshot.forEach((doc) => {
  //           ds.push(doc.data().muuzboxDelSong);
  //         });
  //       });

  //       console.log('ds', ds)
  //         //   const idx = jukebox.findIndex((item) => item.id === delTrack.id && item.ordinal > 0)
  //         //   if(idx !== -1) {
  //         //     console.log('queued track to delete is', jukebox[idx]);
  //         //     const jb = jukebox
  //         //     jb.splice(idx, 1)
  //         //     setJukebox(jb);
  //         //     forceUpdate();
  //         //   }

  //   return () => {
  //     unsubscribe();
  //   }
  // }, [jukebox]);

  const getSiteConfig = () => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db2, `siteconfig`));
      querySnapshot.forEach((doc) => {
        setSiteConfig({
          siteid: doc.data().siteid,
          trackURL: doc.data().trackURL,
          initialTrack: [
            {
              url: doc.data().trackURL,
              title: doc.data().trackTitle,
              artist: doc.data().trackArtist,
              releasePath: doc.data().trackReleasePath,
              tags: doc.data().trackTags,
              coverImage: doc.data().trackCoverImage,
              userplaylist: false,
            },
          ],
        });
      });
    };
    fetchData().catch(console.error);
  };

  useEffect(() => {
    const initAutoplay = async () => {
      getSiteConfig();
      const queryMessages = query(
        userJukeboxRef /* jukeboxRef ,
      where("room", "==", room),
      orderBy("createdAt")*/
      );

      const unsuscribe = onSnapshot(queryMessages, (snapshot) => {
        let jukeboxList = [];
        snapshot.forEach((doc) => {
          jukeboxList.push({ ...doc.data(), id: doc.id });
        });
        setJukeboxList(jukeboxList);
      });

      const docRef = doc(db, "users", auth.currentUser.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setAccountName(docSnap.data().muuzboxAccountName.toLowerCase());
        setCrossfadeDuration(docSnap.data().muuzboxCrossFadeDuration);
      }

      return () => unsuscribe();
    };

    initAutoplay();
  }, []);

  useEffect(() => {
    setJukeboxUserObj(userObj);
  }, [userObj]);

  useEffect(() => {
    setSelectedVideo(videos[0]);
  }, [videos]);

  useEffect(() => {
    // update pending collection for this user with newPending
    if (newPending.length > 0) {
      newPending.forEach((item) => {
        console.log("item", item);
        if (item) {
          const ref = doc(
            db,
            `users`,
            auth.currentUser.uid,
            "pendingList",
            item.id
          );
          setDoc(ref, {
            id: item.id || "",
            artist: item.artist || "",
            track: item.track || "",
            url: item.url,
            genre: item.genre || "",
            coverart: item.coverart || "",
            youTubeId: item.youTubeId || "",
            requester: item.requester || "",
            uid: item.uid || "",
            updatedAt: serverTimestamp(),
            ordinal: item.ordinal || "",
          });
        }
      });
    }
  }, [newPending]);

  useEffect(() => {
    const init = async () => {
      // update pending collection for this user with newPending
      const plv = [];
      console.log("this hsould be an array: oldPending", oldPending);
      if (oldPending.length > 0) {
        oldPending.forEach((item) => {
          console.log("delete item", item);
          if (item) {
            const ref = doc(
              db,
              `users`,
              auth.currentUser.uid,
              "pendingList",
              item.id
            );
            deleteDoc(ref);
            plv.push(item);

            // delete this from pendingListVotes too.
            // only the ones matching user and uid, I think?
            // still needs to differntiate between different requests of the same track
            // get the pendingListVotes and see if they match
            // const votesRef = collection(
            //   db,
            //   "users",
            //   auth.currentUser.uid,
            //   "pendingListVotes"
            // );

            // const votesQuery = query(
            //   votesRef,
            //   where("trackId", "==", item.id)
            // );

            // deleteDoc(votesQuery);

            // const unsubscribe = onSnapshot(votesRef, (snapshot) => {
            //   snapshot.forEach((doc) => {
            //     // jukeboxList.push({ ...doc.data(), id: doc.id });
            //     const trackId = doc.data().trackId;
            //     if (trackId === item.id) {
            //       const plvref = doc(
            //         db,
            //         "users",
            //         auth.currentUser.uid,
            //         "pendingListVotes",
            //         doc.id
            //       );
            //       deleteDoc(plvref);
            //     }
            //   });
            // });
            // return () => unsubscribe();
          }
        });

        // now get rid of plv
        console.log("plv", plv);
        console.log("plv length", plv.length);
        if (plv.length > 0) {
          plv.forEach(async (plvitem) => {
            console.log("plv item", plvitem);

            const collectionRef = collection(
              db,
              "users",
              auth.currentUser.uid,
              "pendingListVotes"
            );
            const q = query(collectionRef, where("trackId", "==", plvitem.id));

            const querySnapshot = await getDocs(q);
            const batch = writeBatch(db);

            querySnapshot.forEach((doc) => {
              batch.delete(doc.ref);
            });

            await batch.commit();
            console.log("Documents deleted successfully");
          });
        }
      }
    };
    init();
  }, [oldPending]);

  const createGenreKey = (genre) => {
    if (genre) {
      //take all spacs and non alphnumeric characters out of genre
      let shortGenre = genre.replace(/\s/g, "");
      shortGenre = shortGenre.replace(/[^a-zA-Z0-9]/g, "");
      shortGenre = shortGenre.toLowerCase();
      return shortGenre;
    } else {
      return "none";
    }
  };

  const groupTracksByGenre = (tracks) => {
    const genredtracks = [];
    tracks.forEach((item) => {
      if (item.genre && item.genre.includes("/")) {
        let newGenres = item.genre.split("/");
        newGenres.forEach((newitem) => {
          genredtracks.push({
            coverart: item.coverart,
            artist: item.artist,
            track: item.track,
            genre: newitem,
            genrekey: createGenreKey(newitem),
          });
        });
      } else {
        item.genrekey = createGenreKey(item.genre);
        genredtracks.push(item);
      }
    });
    return genredtracks;
  };

  useEffect(() => {
    const chatsMessgesRef = collection(
      db,
      "users",
      auth.currentUser.uid,
      "djchats",
      DJChatId,
      "messages"
    );
    const queryMessages = query(chatsMessgesRef, orderBy("createdAt"));
    const unsuscribe = onSnapshot(queryMessages, (snapshot) => {
      let messagesx = [];
      let avatars = [];

      snapshot.forEach((doc) => {
        // create an avatar list that can be referred to when the chatlist is rendered
        avatars.push(doc.data().uid);
        messagesx.push(Object.assign({ ...doc.data(), id: doc.id }));
      });

      new Promise(function (resolve, reject) {
        const avatarObj = {};
        const uniq = [...new Set(avatars)];
        let i = 0;
        // search for these uids and the photoURL that goes with them
        uniq.forEach(async (uid) => {
          i++;
          const docRef = doc(db, "users", uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            avatarObj[uid] = {
              displayName: docSnap.data().displayName,
              photoURL: docSnap.data().photoURL,
              email: docSnap.data().email,
            };
          } else {
            // some random image maybe an ai generated icon and displayname
            avatarObj[uid] = {
              displayName: docSnap.data().displayName || "",
              photoURL: docSnap.data().photoURL || "",
            };
          }
        });
        if (i >= uniq.length) resolve(avatarObj);
        else reject();
      })
        .then(function (avatarObj) {
          return new Promise((resolve, reject) => {
            setTimeout(() => resolve(setAvatars(avatarObj)), 300);
          });
        })
        .then(function (result) {
          setMessages(messagesx);
        });
    });
    return () => unsuscribe();
  }, [DJChatId]);

  const checkUserPickInList = (item) => {
    // console.log("checkUserPickInList", item.uid);
    // check if user has already picked a song in the last 20 minutes
    const requestFeedbackRef = collection(
      db,
      "users",
      auth.currentUser.uid,
      "djchats"
    );
    const queryMessages = query(
      requestFeedbackRef,
      where("uid", "==", item.uid),
      orderBy("createdAt")
    );
    onSnapshot(queryMessages, (snapshot) => {
      let requestFeedback = [];
      snapshot.forEach((doc) => {
        const thisItemtimeStamp = doc.data().createdAt;
        const timestampInSeconds = (Date.now() / 1000) | 0;

        if (timestampInSeconds - thisItemtimeStamp.seconds < 1200) {
          console.log(
            "you have already requested a song in the last 20 minutes"
          );
        } else {
          requestFeedback.push({ ...doc.data(), id: doc.id });
        }
      });
      // console.log("requestFeedback", requestFeedback);
    });
  };

  const arraySearch = (array, keyword) => {
    const searchTerm = keyword.toLowerCase();
    return array.filter((value) => {
      if (!value.track) value.track = value.artist;
      if (!value.artist) value.artist = value.track;
      if (!value.artist && !value.track) return null;
      return (
        value.track.toLowerCase().match(new RegExp(searchTerm, "g")) ||
        value.artist.toLowerCase().match(new RegExp(searchTerm, "g"))
      );
    });
  };

  const handleSubmit = async (event) => {
    let value = event.target.value;
    if (value.length > 2) {
      let search = await arraySearch(jukebox, value);
      setJukebox(search);
      setCount(search.length);
    } else {
      setJukebox(originalJukebox);
      setCount(originalJukebox.length);
    }
  };

  const handleSubmitDisplayname = async () => {
    if (newDisplayName.length > 2 || newDisplayName.length === 0) {
      if (newDisplayName.length === 0) {
        setNewDisplayName(userObj.displayName);
      }
      const ref = doc(db, `users`, auth.currentUser.uid);
      updateDoc(ref, {
        displayName: newDisplayName,
        nameChanged: true,
      });
      const newObj = jukeboxUserObj;
      newObj.displayName = newDisplayName;
      newObj.nameChanged = true;
      setJukeboxUserObj(newObj);
      setDisplayNameOpen(false);
      setIsOpen(!isOpen);
      // now send this request to dj
      sendThisPickToDJ(currentRequestDetails);
    } else {
      alert("new name needs to be longer");
    }
  };

  const handleUpdateDisplayname = async (event) => {
    let value = event.target.value;
    if (value.length > 2) {
      setNewDisplayName(value);
    }
  };

  const pickTrack = async (event, track, i) => {
    setRequestedTrack(track);
    const ii = jukebox.findIndex((item) => item.id === track.id);
    setShowVideoPlayback(false);
    setShowTrackForm(false);
    setVideoId(track.youTubeId);
    setCurrentPlayingTrackIndex(ii);

    if (ii >= jukebox.length - 1) {
      setNextPlayingTrackIndex(1);
    } else {
      setNextPlayingTrackIndex(ii + 1);
    }

    setTimeout(() => {
      setShowVideoPlayback(true);
    }, 300);

    if (isOpen) {
      // setIsOpen(!isOpen);
    } else {
      if (auth.currentUser.isAnonymous) {
        // setIsOpen(false);
        // // console.log("jukeboxUserObj", jukeboxUserObj);
        // if (!jukeboxUserObj.nameChanged) setDisplayNameOpen(true);
        // else {
        //   setIsOpen(!isOpen);
        //   sendThisPickToDJ(item);
        // }
        // // lets check what the user name is
        // // console.log("item", item);
        // // console.log("compare this to freshly entered user name");
        // setCurrentRequestDetails(item);
      } else {
        // setIsOpen(!isOpen);
        // sendThisPickToDJ(item);
        // const user = auth.currentUser;
        // const uid = user.uid;
        // const pickedItem = {
        //   request: item,
        //   createdAt: serverTimestamp(),
        //   user: auth.currentUser.displayName || auth.currentUser.email,
        //   uid: auth.currentUser.uid,
        // }
        //checkUserPickInList(pickedItem);
        // read the whole request list to find if this is already in it
        // request.track request.artist
        // requestList.forEach((request) => {
        //   console.log("request", request.request.track, request.request.artist);
        //   if (
        //     request.request.track === item.track &&
        //     request.request.artist === item.artist
        //   ) {
        //     console.log("this song has already been requested");
        //   }
        //   console.log(
        //     "request.createdAt.seconds in list",
        //     Math.floor(Date.now() / 1000) - request.createdAt.seconds
        //   );
        //   if (Math.floor(Date.now() / 1000) - request.createdAt.seconds < 1201) {
        //     console.log(
        //       "this user has already requested a song in the last 20 minutes"
        //     );
        //   }
        // });
        // const requestSongRef4 = collection(db, "djchats");
        // try {
        //   const newprod = await addDoc(requestSongRef4, {
        //     request: item,
        //     createdAt: serverTimestamp(),
        //     user: auth.currentUser.displayName || auth.currentUser.email,
        //     uid: auth.currentUser.uid,
        //   });
        //   console.log("djchat document written with ID: ", newprod.id);
        //   setDJChatId(newprod.id);
        //   const requestSongRef5 = collection(
        //     db,
        //     "djchats",
        //     newprod.id,
        //     "requesters"
        //   );
        //   try {
        //     const newrequester = await addDoc(requestSongRef5, {
        //       createdAt: serverTimestamp(),
        //       user: auth.currentUser.displayName || auth.currentUser.email,
        //       uid: auth.currentUser.uid,
        //     });
        //     console.log("newrequester id: ", newrequester.id);
        //   } catch (error) {
        //     console.error("Error adding document: ", error);
        //   }
        //   const requestSongRef6 = collection(
        //     db,
        //     "users",
        //     auth.currentUser.uid,
        //     "requests"
        //   );
        //   try {
        //     const userrequest = await addDoc(requestSongRef6, {
        //       createdAt: serverTimestamp(),
        //       djchatId: newprod.id,
        //     });
        //     console.log("userrequest id: ", userrequest.id);
        //   } catch (error) {
        //     console.error("Error adding document: ", error);
        //   }
        // } catch (error) {
        //   console.error("Error adding document: ", error);
        // }
        /*
    this user can now specifically wait for updates to the above record from the dj app
    as it has their uid as its id in djchats collection
    */
      }
    }
  };

  const getCurrentDisplayName = async () => {
    try {
      const requestCurrentIdRef = doc(db, "users", auth.currentUser.uid);
      const user = await getDoc(requestCurrentIdRef, requestCurrentIdRef);
      return user.displayName;
    } catch (err) {
      console.log("error:", err);
    }
  };

  const sendThisPickToDJ = async (item) => {
    let prepareOpenRequest = new Promise(function (resolve, reject) {
      resolve("Promise resolved");
    });

    prepareOpenRequest
      .then(async () => {
        try {
          const requestCurrentIdRef = doc(db, "users", auth.currentUser.uid);
          const user = await getDoc(requestCurrentIdRef, requestCurrentIdRef);
          if (user.exists()) {
            const displayName = user.data().displayName;
            return displayName;
          }
        } catch (err) {
          console.log("error:", err);
        }
      })
      .then(async (displayName) => {
        const requestSongRef4 = collection(
          db,
          "users",
          auth.currentUser.uid,
          "djchats"
        );
        if (newDisplayName !== "") displayName = newDisplayName;
        try {
          const newprod = await addDoc(requestSongRef4, {
            request: item,
            createdAt: serverTimestamp(),
            user: displayName,
            uid: auth.currentUser.uid,
          });
          setDJChatId(newprod.id);
          return newprod;
        } catch (error) {
          console.error("Error adding document: ", error);
        }
      })
      .then(async (newprod) => {
        const requestSongRef5 = collection(
          db,
          "users",
          auth.currentUser.uid,
          "djchats",
          newprod.id,
          "requesters"
        );
        try {
          await addDoc(requestSongRef5, {
            createdAt: serverTimestamp(),
            user: auth.currentUser.displayName || auth.currentUser.email,
            uid: auth.currentUser.uid,
          });
          return newprod;
        } catch (error) {
          console.error("Error adding document: ", error);
        }
      })
      .then(async (newprod) => {
        const requestSongRef5 = collection(
          db,
          "users",
          auth.currentUser.uid,
          "djchats",
          newprod.id,
          "requesters"
        );
        try {
          await addDoc(requestSongRef5, {
            createdAt: serverTimestamp(),
            user: auth.currentUser.displayName || auth.currentUser.email,
            uid: auth.currentUser.uid,
          });
          return newprod;
        } catch (error) {
          console.error("Error adding document: ", error);
        }
      })
      .then(async (newprod) => {
        const requestSongRef6 = collection(
          db,
          "users",
          auth.currentUser.uid,
          "requests"
        );
        try {
          await addDoc(requestSongRef6, {
            createdAt: serverTimestamp(),
            djchatId: newprod.id,
          });
        } catch (error) {
          console.error("Error adding document: ", error);
        }
      });
  };

  const setRequestedTrackSetter = (item) => {
    setRequestedTrack(item);
  };

  const loadAutoPlaylist = (id, tracks, pn) => {
    setPlaylistId(id);
    // console.log("playlsit id", id);
    // console.log('tracks', tracks);
    setPlaylistName(pn);
    setGroupedTracks(groupTracksByGenre(tracks));
    setJukebox(tracks);
    setOriginalJukebox(tracks);
  };

  const handleDragStart = (e, item) => {
    setDraggingItem(item);
    e.dataTransfer.setData("text/plain", "");
  };

  const handleDragEnd = () => {
    setDraggingItem(null);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, targetItem, i) => {
    if (!draggingItem) return;

    const currentIndex = jukebox.indexOf(draggingItem);
    const targetIndex = jukebox.indexOf(targetItem);

    let p = jukebox;
    if (currentIndex !== -1 && targetIndex !== -1) {
      p.splice(currentIndex, 1);
      p.splice(targetIndex, 0, draggingItem);
      // console.log("p", p);
      setJukebox(p);
    }
    setTimeout(() => {
      if (
        currentIndex > currentPlayingTrackIndex &&
        targetIndex < currentPlayingTrackIndex
      ) {
        setCurrentPlayingTrackIndex(currentPlayingTrackIndex + 1);

        if (currentPlayingTrackIndex + 1 >= jukebox.length - 1) {
          setNextPlayingTrackIndex(1);
        } else {
          setNextPlayingTrackIndex(currentPlayingTrackIndex + 2);
        }
      } else if (
        currentIndex < currentPlayingTrackIndex &&
        targetIndex > currentPlayingTrackIndex
      ) {
        setCurrentPlayingTrackIndex(currentPlayingTrackIndex - 1);

        if (currentPlayingTrackIndex - 1 <= 0) {
          setNextPlayingTrackIndex(1);
        } else {
          setNextPlayingTrackIndex(currentPlayingTrackIndex);
        }
      }
      forceUpdate();
    }, 300);
  };

  // const playNextTrack = () => {
  //   console.log('playNextTrack');
  //   setShowVideoPlayback(false);
  //   setShowMP3Playback(false);
  //   const i = currentPlayingTrackIndex;
  //   const originIdx = i;
  //   let track;
  //   if (jukebox.length === i + 1) {
  //     setCurrentPlayingTrackIndex(0);
  //     track = jukebox[0];
  //   } else {
  //     setCurrentPlayingTrackIndex(i + 1);
  //     track = jukebox[i + 1];
  //   }
  // console.log('currentPlayingTrackIndex', currentPlayingTrackIndex);
  // // does this track have a videoID
  // if(track.youTubeId){

  //   setVideoId(track.youTubeId);
  //   setTimeout(() => {
  //     setShowVideoPlayback(true);
  //   }, 300);
  // } else if (track.url) {
  //   setTimeout(() => {

  //     console.log('going for the local mp3 player:', currentPlayingTrackIndex)
  //     const changeCurTrackIndex = { curTrack: currentPlayingTrackIndex }
  //     console.log('changeCurTrackIndex', changeCurTrackIndex);
  //     setMessage(JSON.stringify(changeCurTrackIndex));
  //     setShowVideoPlayback(false);
  //     setShowTrackForm(false);
  //     setTrackList(jukebox);
  //     // setCurrentPlayingTrackIndex(originIdx);
  //     setTimeout(() => {
  //       setShowMP3Playback(true);
  //       // setCurrentPlayingTrackIndex(currentPlayingTrackIndex);
  //     }, 600);

  //   }, 600);

  // } else {
  //   console.log("this track as no media attached to it that can be played");
  // }

  // };

  useEffect(() => {
    const getChannel = async () => {
      const getDefaultPlaylist = async (plId) => {
        let prepareList = new Promise(function (resolve, reject) {
          resolve("Promise resolved");
        });

        prepareList.then(async () => {});
        const listRef = collection(db, "playlists", plId, "tracks");
        const queryTracks = query(listRef, orderBy("ordinal"));
        const unsubscribe = onSnapshot(queryTracks, (snapshot) => {
          let playlist = [];
          let trackDocRef;
          snapshot.forEach(async (docItem) => {
            trackDocRef = doc(
              db,
              "playlists",
              plId,
              "tracks",
              docItem.data().id
            );
            const docSnap = await getDoc(trackDocRef);
            if (docSnap.exists()) {
              playlist.push({
                id: docSnap.id,
                track: docSnap.data().track,
                genre: docSnap.data().genre,
                artist: docSnap.data().artist,
                coverart: docSnap.data().coverart,
                url: docSnap.data().url,
                youTubeId: docSnap.data().youTubeId,
                filename: docSnap.data().filename,
              });
            }
          });

          setTimeout(() => {
            setGroupedTracks(groupTracksByGenre(playlist));
            setJukebox(playlist);
            setOriginalJukebox(playlist);
          }, 1000);
        });
        return () => unsubscribe();
      };

      if (accountName) {
        const docRef2 = doc(db, "clients", accountName);
        const docSnap2 = await getDoc(docRef2);
        if (docSnap2.exists()) {
          setDefaultPlaylist(docSnap2.data().defaultPlaylist || "");
          setPlaylistName(docSnap2.data().defaultPlaylist || "");
          // setAvatarImage(docSnap2.data().avatarImage || "");

          const idx = jukeboxList.findIndex(
            (item) => item.playlistName === docSnap2.data().defaultPlaylist
          );

          if (idx !== -1) {
            const plId = jukeboxList[idx].id;
            setPlaylistId(plId);
            getDefaultPlaylist(plId);
          }
        }
      }
    };
    getChannel();
  }, [accountName, jukeboxList]);

  useEffect(() => {
    // update this DJ user with current track details muuzboxCurrentSong
    // if(!currentSong?.filename) currentSong.filename = "";

    // console.log('currentSong', currentSong);
    if (currentSong) {
      let cs = {
        id: currentSong.id || "",
        artist: currentSong.artist || "",
        track: currentSong.track || "",
        url: currentSong.url,
        filename: currentSong.filename || "",
        genre: currentSong.nmlgenre || "",
        coverart: currentSong.coverart || "",
        youTubeId: currentSong.youTubeId || "",
      };
      // let ns = {}
      // if(nextSong){
      //   ns = {
      //     id: nextSong.id || "",
      //     artist: nextSong.artist || "",
      //     track: nextSong.track || "",
      //     url: nextSong.url,
      //     filename: nextSong.filename || "",
      //     genre: nextSong.nmlgenre || "",
      //     coverart: nextSong.coverart || "",
      //     youTubeId: nextSong.youTubeId || "",
      //   };
      // }
      // console.log("setting current song", cs); //compensate for stream tracks
      const ref = doc(
        db,
        `users`,
        auth.currentUser.uid,
        "currentSong",
        "CURRENTSONG"
      );
      setDoc(ref, {
        muuzboxCurrentSong: cs,
        updatedAt: serverTimestamp(),
      });

      if(nextSong){

        let ns = {
          id: nextSong.id || "",
          artist: nextSong.artist || "",
          track: nextSong.track || "",
          url: nextSong.url,
          filename: nextSong.filename || "",
          genre: nextSong.nmlgenre || "",
          coverart: nextSong.coverart || "",
          youTubeId: nextSong.youTubeId || "",
        };

        const ref2 = doc(
          db,
          `users`,
          auth.currentUser.uid,
          "nextSong",
          "NEXTSONG"
        );
        setDoc(ref2, {
          muuzboxNextSong: ns,
          updatedAt: serverTimestamp(),
        });
      }

      // if (previousCurrentSongId) {
      //   const prevplref = doc(
      //     db,
      //     `playlists`,
      //     playlistId,
      //     "tracks",
      //     previousCurrentSongId
      //   );
      //   updateDoc(prevplref, {
      //     currentsong: "played",
      //   });
      // }
      // if (currentSong) {
      //   console.log("playlistId", playlistId);
      //   console.log("currentSong.id", currentSong.id);

      //   const plref = doc(db, `playlists`, playlistId, "tracks", currentSong.id);
      //   updateDoc(plref, {
      //     currentsong: "true",
      //     lastplayedAt: serverTimestamp(),
      //   });
      //   setPreviousCurrentSongId(currentSong.id);
      // }
    }
  }, [currentSong, nextSong, playlistId, previousCurrentSongId]);

  const handleVideoPlayback = (e, track, i) => {
    e.preventDefault();
    // console.log('i',i)
    const originIdx = i;
    if (i === null) {
      i = track.ordinal;
    }

    // whatever is next in jukebox unless track is last track, therefore i must by last index
    if (i >= jukebox.length - 1) {
      setNextSong(jukebox[1]);
    } else {
      console.log("*ns track", track);

      let nsIdx = 1;
      while (
        jukebox[i + nsIdx].queued === true ||
        jukebox[i + nsIdx].skip === true
      ) {
        nsIdx++;
      }

      console.log("*ns nextTrack", jukebox[i + nsIdx]);
      setNextSong(jukebox[i + nsIdx]); // is this a requested song? Lets now only put non requested songs as next song
    }

    setCurrentSong(track);
    const op = [];
    op.push(track);
    setOldPending(op); // remove from pending collection

    if (track.youTubeId) {
      // must first send message to audio player to stop before hiding it
      const pause = { pause: true };
      setMessage(JSON.stringify(pause));

      setTimeout(() => {
        setShowMP3Playback(false);
        setShowVideoPlayback(false);
        setShowTrackForm(false);
        setVideoId(track.youTubeId);
        setCurrentPlayingTrackIndex(i);

        if (i >= jukebox.length - 1) {
          setNextPlayingTrackIndex(1);
        } else {
          setNextPlayingTrackIndex(i + 1);
        }

        setTimeout(() => {
          setShowVideoPlayback(true);
        }, 300);
      }, 300);
    } else if (track.url) {
      // console.log("going for the local mp3 player");
      setStopAudio(false);
      // console.log('originIdx', originIdx)

      if (originIdx !== 0) {
        const changeCurTrackIndex = { curTrack: originIdx };
        setMessage(JSON.stringify(changeCurTrackIndex));
      } else {
        const rand = Math.floor(Math.random() * 1000);
        const changeCurTrackIndex = { curTrackZero: rand };
        setMessage(JSON.stringify(changeCurTrackIndex));
      }

      setShowVideoPlayback(false);
      setShowTrackForm(false);
      setTrackList(jukebox);
      setCurrentPlayingTrackIndex(originIdx);
      if (originIdx + 1 >= jukebox.length - 1) {
        setNextPlayingTrackIndex(1);
      } else {
        setNextPlayingTrackIndex(originIdx + 1);
      }
      setTimeout(() => {
        setShowMP3Playback(true);
      }, 300);
    } else {
      console.log("this track as no media attached to it that can be played");
    }
  };

  const handleEditTrack = (e, track) => {
    e.preventDefault();
    // track.id
    setTrackId(track.id);
    setTrack(track.track);
    setArtist(track.artist);
    setGenre(track.genre);
    setUrl(track.url);
    setYouTubeId(track.youTubeId);
    setCoverart(track.coverart);

    setShowVideoPlayback(false);
    setShowTrackForm(true);
  };

  const playNextTrack = () => {
    setShowVideoPlayback(false);
    const i = currentPlayingTrackIndex;
    let originIdx = currentPlayingTrackIndex + 1;
    let track;
    let nextTrack;
    if (i + 1 >= jukebox.length) {
      setShowMP3Playback(false);
      originIdx = 0;
      setCurrentPlayingTrackIndex(0);
      setNextPlayingTrackIndex(1);
      initialisePlaylist().then(() => {
        track = jukebox[0];
        nextTrack = jukebox[1];
      });
    } else {
      setCurrentPlayingTrackIndex(i + 1);

      // actually this is where to determine if track is to be skipped
      let idx = 1;
      while (jukebox[i + idx].skip === true) {
        idx++;
      }
      track = jukebox[i + idx];
      originIdx = i + idx;
      let nsIdx = idx + 1;


      // check if there are queued pending tracks
      // check if track is skippable due to down votes also
      console.log("*&* check for skippable");
      while (
        jukebox[i + nsIdx] && jukebox[i + nsIdx].queued === true 
      ) {
        console.log("*&* jukebox[i + nsIdx]", jukebox[i + nsIdx]);
        nsIdx++;
      }

      setNextPlayingTrackIndex(i + nsIdx);
      nextTrack = jukebox[i + nsIdx];

      console.log("*&* *ns track", track);
      console.log("*&*  *ns nextTrack", nextTrack);
    }

    setCurrentSong(track);
    setNextSong(nextTrack); // is this a requested song. lets only put non requested songs as next song

    //
    // find this track in jukebox and park queued as false
    if(track){
      const jb = jukebox;
      const idx = jb.findIndex((item) => item.id === track.id);
      if (idx !== -1) {
        jb[idx].queued = false;
        setJukebox(jb);
      }
      const op = [];
      op.push(track);
      console.log('*&* oldpending', op);
      setOldPending(op); // remove from pending collection
    } else {
      console.log('no track data, so initialise please')
      setShowMP3Playback(false);
      originIdx = 0;
      setCurrentPlayingTrackIndex(0);
      setNextPlayingTrackIndex(1);
      initialisePlaylist().then(() => {
        track = jukebox[0];
        nextTrack = jukebox[1];
        setCurrentSong(track);
        setNextSong(nextTrack); // is this a requested song. lets only put non requested songs as next song
      });
    }




    if (track.youTubeId) {
      setShowMP3Playback(false);
      setVideoId(track.youTubeId);
      setTimeout(() => {
        setShowVideoPlayback(true);
      }, 300);
    } else if (track.url) {
      // console.log("going for the local mp3 player");
      setStopAudio(false);
      const changeCurTrackIndex = { curTrack: originIdx };
      setMessage(JSON.stringify(changeCurTrackIndex));
      setShowVideoPlayback(false);
      setShowTrackForm(false);
      setTrackList(jukebox);
      setCurrentPlayingTrackIndex(originIdx);
      setTimeout(() => {
        setShowMP3Playback(true);
      }, 300);
    } else {
      console.log("this track as no media attached to it that can be played");
    }
  };

  const initialisePlaylist = async () => {
    // before anything, clear the pending playlist out
    const collectionRef = collection(
      db,
      "users",
      auth.currentUser.uid,
      "pendingList"
    ); // Change to your collection name
    // const q = query(collectionRef, where("status", "==", "expired")); // Adjust your query

    const querySnapshot = await getDocs(collectionRef);
    const batch = writeBatch(db);

    querySnapshot.forEach((doc) => {
      batch.delete(doc.ref);
    });

    await batch.commit();
    console.log("Documents deleted successfully");

    let playlist = [];
    const playlistRef = collection(db, "playlists", playlistId, "tracks");
    const queryList = query(playlistRef, orderBy("ordinal")); //, where("createdAt", "<", serverTimestamp()), orderBy("createdAt"));
    const unsuscribe = onSnapshot(queryList, (snapshot) => {
      playlist = [];
      snapshot.forEach(async (docItem) => {
        //const trackDocRef = doc(db, "tracks", docItem.data().id);
        const trackDocRef = doc(
          db,
          "playlists",
          playlistId,
          "tracks",
          docItem.data().id
        );
        const docSnap = await getDoc(trackDocRef);
        if (docSnap.exists()) {
          // console.log('docSnap.data().filename', docSnap.data().filename)
          playlist.push({
            id: docSnap.id,
            track: docSnap.data().track,
            genre: docSnap.data().genre,
            artist: docSnap.data().artist,
            coverart: docSnap.data().coverart,
            url: docSnap.data().url,
            youTubeId: docSnap.data().youTubeId,
            filename: docSnap.data().filename,
          });
        }
      });
      // setTimeout(() => {
      //   setShowLoadingAni(false);
      //   loadAutoPlaylist(playlistId.id, playlist, playlistName);
      // }, 1500);
    });

    return () => unsuscribe();
  };

  return (
    <>
      <div className="flex flex-col flex-auto sm:items-stretch md:item-stretch jukebox-panel eebbzz">
        {/* DJ / User details */}
        <div className="flex flex-row text-white items-center pb-3 font-black">
          <div class="flex justify-center h-16 w-16 md:h-16 md:w-16 max-h-16 max-w-16 mx-0.5 my-0.5 rounded-full border overflow-hidden text-center items-center ">
            <div>
              <img
                src={jukeboxUserObj.photoURL}
                alt={jukeboxUserObj.displayNamel}
                title={jukeboxUserObj.displayName}
              />
            </div>
          </div>

          <div className="text-sm mx-2 my-1">
            {jukeboxUserObj.displayName}
            <p className="text-gray-100 text-base">Member</p>
          </div>
        </div>
        <div className="text-white text-center font-black text-xs uppercase pb-2">
          Playlists
        </div>

        <div className="flex flex-col flex-auto flex-shrink-0 rounded-2xl bg-gray-100 h-full p-4 panel-fade">
          <div
            className={`flex flex-row items-center h-16 rounded-xl bg-white px-4 h-24 m-12 panel-fade text-enter-fit ${
              inputFocus === true ? "text-enter-full" : ""
            }`}
            style={{
              position: "absolute",
              bottom: "9rem",
              left: "2rem",
              margin: "1.5rem",
            }}
          >
            {/* audio playback */}
            {showAudioPlayback && (
              <div className={`flex flex-row justify-center items-center`}>
                <Player
                  trackList={siteConfig.initialTrack}
                  clearList={siteConfig.initialTrack}
                  jukebox={jukebox}
                />
              </div>
            )}

            {/* audio playback */}
            {showMP3Playback && (
              <div className="pl-4 flex items-center w-full">
                <div className="editplayer">
                  {/* <div>{jukebox[currentPlayingTrackIndex].track}</div> */}
                  <div className="flex flex-row justify-center items-center">
                    <Player4
                      trackList={jukebox}
                      jukebox={jukebox}
                      currentPlayingTrackIndex={currentPlayingTrackIndex}
                      playNextTrack={playNextTrack}
                      stopAudio={stopAudio}

                      // trackList={jukebox}
                      // clearList={siteConfig.initialTrack}
                      // jukebox={jukebox}
                      // currentPlayingTrackIndex={currentPlayingTrackIndex}
                      // trackArtist={jukebox[currentPlayingTrackIndex].artist || ""}
                      // trackTitle={jukebox[currentPlayingTrackIndex].track || ""}
                      // playNextTrack={playNextTrack}
                    />
                  </div>
                </div>
              </div>
            )}

            {/* stream playback */}
            {showVideoPlayback && (
              <div className={`flex flex-row justify-center items-center`}>
                <VideoPlayback4
                  videoId={videoId}
                  playNextTrack={playNextTrack}
                  trackArtist={jukebox[currentPlayingTrackIndex].artist || ""}
                  trackTitle={jukebox[currentPlayingTrackIndex].track || ""}
                  // deck={1}
                  // setCurrentDeck={setCurrentDeck}
                />
              </div>
            )}
            {/* {showVideoPlayback2 && (
              <div className={`flex flex-row justify-center items-center  ${
                currentDeck === 2 ? "invisible" : ""
              } `}>
                <VideoPlayback4
                  videoId={videoId}
                  playNextTrack={playNextTrack}
                  trackArtist={jukebox[currentPlayingTrackIndex].artist}
                  trackTitle={jukebox[currentPlayingTrackIndex].track}
                  deck={2}
                  setCurrentDeck={setCurrentDeck}
                />
              </div>
            )} */}
          </div>

          <div className="flex flex-row autoplay-content">
            <div className="flex flex-col overflow-x-auto mb-4 scrollable-section no-scrollbar">
              <div className="flex flex-row  h-full no-scrollbar">
                <div className="muuzbox-list">
                  {displayNameOpen && (
                    <div className="bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 muuz-displayname-panel">
                      <div>
                        CURRENT DISPLAY NAME
                        <input
                          placeholder={userObj.displayName}
                          type="text"
                          name="newdisplayname"
                          id="newdisplayname"
                          onKeyDown={(e) => {
                            if (e.key === "Enter") handleSubmitDisplayname(e);
                          }}
                          onChange={handleUpdateDisplayname}
                          className="flex w-full text-gray-800 dark:text-gray-800 border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-20"
                        />
                        <button
                          onClick={(e) => {
                            setDisplayNameOpen(false);
                            handleSubmitDisplayname(e);
                          }}
                          className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-lg px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700 muuzmenuitem"
                        >
                          update your name to continue
                        </button>
                      </div>
                    </div>
                  )}
                  {isOpen && (
                    <div className="bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 muuz-confirm-panel">
                      <div
                        className="py-2 text-sm text-gray-700 dark:text-gray-200"
                        aria-labelledby="dropdownDefaultButton"
                      >
                        <div className="text-center">
                          <div
                            onClick={() => {
                              setIsOpen(!isOpen);
                            }}
                            class="muuzbox-section-button"
                          >
                            <div
                              id="notifyMuuzbox-close"
                              class="section-close muuzbox-close rounded"
                            ></div>
                          </div>

                          <button
                            onClick={() => {
                              setIsOpen(!isOpen);
                            }}
                            className="block w-full text-center px-4 py-2 hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white muuzmenuitem"
                          >
                            <div>
                              <strong>{requestedTrack.artist}</strong>
                            </div>
                            <div>{requestedTrack.track}</div>
                          </button>
                          <div className="block w-full text-xl text-center text-red-800">
                            DJ OBAH has been informed of your request
                          </div>
                        </div>

                        {messages && (
                          <>
                            {messages.map((message, i) => (
                              <div
                                className="flex flex-row items-center hover:bg-gray-100 rounded-xl p-2"
                                key={i}
                              >
                                <div className="flex items-center justify-center h-12 w-12 rounded-full border overflow-hidden bg-indigo-500 flex-shrink-0">
                                  <img
                                    src={avatars[message.uid]?.photoURL}
                                    alt={avatars[message.uid]?.displayName}
                                  />
                                </div>
                                <div className="ml-2 text-lg font-semibold">
                                  {message.text}
                                </div>
                              </div>
                            ))}
                          </>
                        )}

                        {messages.length === 0 && (
                          <div class="muuzbox-loader-logo-sm fadeIn">
                            <img
                              src="img/muuzbox-logo.png"
                              alt="Muuzbox logo"
                            />
                            <div class="muuzbox-loader-blob"></div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <h2 className="text-white">AUTO PLAY [{playlistName}]</h2>
                  <AccordionList
                    data={groupedTracks}
                    setRequestedTrack={setRequestedTrackSetter}
                    pickTrack={pickTrack}
                    listTitle="Playlist"
                    mode={"dark"}
                  />

                  <div className="sortable-list">
                    {jukebox.map((item, i) => (
                      <div
                        key={i}
                        className={`item ${
                          item === draggingItem ? "dragging" : ""
                        }`}
                        draggable="true"
                        onDragStart={(e) => handleDragStart(e, item)}
                        onDragEnd={handleDragEnd}
                        onDragOver={handleDragOver}
                        onDrop={(e) => handleDrop(e, item, i)}
                      >
                        <div
                          className="flex items-center py-4 px-3 jukebox-divider"
                          key={i}
                          onClick={(event) => {
                            setRequestedTrack(item);
                          }}
                        >
                          <span className="text-white text-lg font-black font-medium">
                            {i + 1}.
                          </span>
                          <img
                            className=" rounded-full object-cover mr-3 ml-3"
                            src={item.coverart}
                            style={{ width: "48px", height: "48px" }}
                            alt={item.track}
                          />
                          <div
                            className="flex-1 text-left"
                            style={{ width: "50%" }}
                          >
                            <h3 className="text-lg font-medium text-white overflow-hidden">
                              {item.artist}
                            </h3>
                            <p className="text-white text-base">
                              {item.track}{" "}
                            </p>
                            <p className="text-white text-base">
                              {item.genre}{" "}
                              {item.queued && (
                                <>
                                  <span className="text-yellow-800">
                                    {" "}
                                    : REQUESTED
                                  </span>
                                </>
                              )}
                              {item.skip && (
                                <>
                                  <span className="text-yellow-800">
                                    {" "}
                                    : SKIP
                                  </span>
                                </>
                              )}
                            </p>
                          </div>
                          <div>
                            {/* play button */}
                            <button
                              onClick={(event) =>
                                handleVideoPlayback(event, item, i)
                              }
                              className={`flex items-center justify-center ${
                                i === currentPlayingTrackIndex
                                  ? "bg-red-500 hover:bg-red-600"
                                  : "bg-indigo-500 hover:bg-indigo-600"
                              }   rounded-md text-white px-4 py-1  w-6 h-6 mt-4 mr-2`}
                            >
                              <span className="ml-0">
                                <svg
                                  alt={"edit " + item.track}
                                  title={"edit " + item.track}
                                  className="w-4 h-4 -mt-px text-white"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M21.4086 9.35258C23.5305 10.5065 23.5305 13.4935 21.4086 14.6474L8.59662 21.6145C6.53435 22.736 4 21.2763 4 18.9671L4 5.0329C4 2.72368 6.53435 1.26402 8.59661 2.38548L21.4086 9.35258Z"
                                    fill="#FFFFFF"
                                  />
                                </svg>
                              </span>
                            </button>

                            {/* edit button */}
                            <button
                              onClick={(event) => handleEditTrack(event, item)}
                              className="flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 rounded-md text-white px-4 py-1  w-6 h-6 mt-4 mr-2"
                            >
                              <span className="ml-0">
                                <svg
                                  alt={"edit " + item.track}
                                  title={"edit " + item.track}
                                  className="w-4 h-4 -mt-px text-white"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M20.8477 1.87868C19.6761 0.707109 17.7766 0.707105 16.605 1.87868L2.44744 16.0363C2.02864 16.4551 1.74317 16.9885 1.62702 17.5692L1.03995 20.5046C0.760062 21.904 1.9939 23.1379 3.39334 22.858L6.32868 22.2709C6.90945 22.1548 7.44285 21.8693 7.86165 21.4505L22.0192 7.29289C23.1908 6.12132 23.1908 4.22183 22.0192 3.05025L20.8477 1.87868ZM18.0192 3.29289C18.4098 2.90237 19.0429 2.90237 19.4335 3.29289L20.605 4.46447C20.9956 4.85499 20.9956 5.48815 20.605 5.87868L17.9334 8.55027L15.3477 5.96448L18.0192 3.29289ZM13.9334 7.3787L3.86165 17.4505C3.72205 17.5901 3.6269 17.7679 3.58818 17.9615L3.00111 20.8968L5.93645 20.3097C6.13004 20.271 6.30784 20.1759 6.44744 20.0363L16.5192 9.96448L13.9334 7.3787Z"
                                    fill="#FFFFFF"
                                  />
                                </svg>
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col overflow-x-auto mb-4 scrollable-section">
              <div className="flex flex-row  h-full">
                <div className="muuzbox-shop text-white p-20">
                  <JukeboxRequests loadAutoPlaylist={loadAutoPlaylist} />
                  <ul>
                    <li>
                      <h2>THIS IS OBAH</h2>
                      <div className="rounded-2xl bg-gray-100 h-full p-4 panel-fade">
                        <img
                          src="https://thisisobah.com/img/icons/obah-entertainment.png"
                          title="This Is Obah Entertainment"
                          alt="This Is Obah Entertainment"
                        />
                      </div>
                    </li>
                    <li>
                      <h2>Muuzbox Merch</h2>
                      <div className="rounded-2xl bg-gray-100 h-full p-4 panel-fade">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/chatbout001.appspot.com/o/users%2FBs7gqBrNUtMBmM7jgEyGmCKtSNl1%2Ffiles%2Fkali-zing-shirt.png?alt=media&token=f3a310a5-ebf8-401f-9d32-917032bc8c44"
                          title="Muuzbox Merch"
                          alt="Muuzbox Merch"
                        />
                      </div>
                    </li>
                    <li>
                      <h2>CTFO MUSIC</h2>
                      <div className="rounded-2xl bg-gray-100 h-full p-4 panel-fade">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/chatbout001.appspot.com/o/users%2FL8s7DzpsAvabSIyNx0xxeuhuryM2%2Ffiles%2FCTFO-Music-Logo.png?alt=media&token=b0900a88-84e0-4471-9c27-b038ab1d1995"
                          title="ctfo music"
                          alt="ctfo music"
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

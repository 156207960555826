import React, { useState } from "react";
import cn from "classnames";

const genreSliderStyles = [
  { "--duration": "15951ms", "--direction": "normal" },
  { "--duration": "19260ms", "--direction": "reverse" },
  { "--duration": "10449ms", "--direction": "normal" },
  { "--duration": "16638ms", "--direction": "reverse" },
  { "--duration": "15936ms", "--direction": "normal" },
];

const splitIntoGroups = (arr, chunkSize) => {
  const result = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    result.push(arr.slice(i, i + chunkSize));
  }
  return result;
};

const TrackList = ({
  tracks,
  setRequestedTrack,
  pickTrack,
  mode,
  showChatlist,
  setGenrePlaylist,
  genre,
}) => {
  const [activeIndex, setActiveIndex] = React.useState(null);

  const handleItemClick = (gpl, g) => {
    setGenrePlaylist(gpl);
    genre(g);
  };

  // Group tracks by genre
  const batched = Object.keys(groupTracksByGenre(tracks));
  const groupedTracks = splitIntoGroups(batched, 50);

  return (
    <>
      {/* <div className="accordion-list"> */}
        {/* <div className="accordion-container"> */}
          <div
            className={`tag-list ${showChatlist === true ? "activated" : ""}`}
          >
            {groupedTracks.map((genrerow, i) => (
              <div key={i} className="loop-sliderx" style={genreSliderStyles[i]}>
                <div className="inner">
                  {genrerow.map((genre) => (
                    <div
                      className="tag"
                      onClick={() =>
                        handleItemClick(
                          groupTracksByGenre(tracks)[genre],
                          genre
                        )
                      }
                    >
                      <span>#</span>
                      {genre}
                    </div>
                  ))}
                  {/* {genrerow.map((genre) => (
                    <div
                      className="tag"
                      onClick={() =>
                        handleItemClick(
                          groupTracksByGenre(tracks)[genre],
                          genre
                        )
                      }
                    >
                      <span>#</span>
                      {genre}
                    </div>
                  ))} */}
                </div>
              </div>
            ))}
          </div>
        {/* </div>
      </div> */}
    </>
  );
};

// Utility function to group tracks by genre
const groupTracksByGenre = (tracks) => {
  return tracks.reduce((acc, track) => {
    if (!acc[track.genrekey]) {
      acc[track.genrekey] = [];
    }
    acc[track.genrekey].push(track);
    return acc;
  }, {});
};

const App = ({
  data,
  pickTrack,
  setRequestedTrack,
  listTitle,
  mode,
  showChatlist,
  setGenrePlaylist,
}) => {
  const [pickdGenre, setPickedGenre] = useState(null);
  const genre = (g) => {
    setPickedGenre(g);
  };
  return (
    <div className="">
      <h2
        className={cn("", {
          "text-black": mode === "light",
          "text-white": mode === "dark",
        })}
      >
        <span className="mr-3">{listTitle}</span> 
        {pickdGenre !== null && (
          <button
            type="button"
            class="text-black bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-yellow-300 dark:focus:ring-yellow-800 font-medium rounded-lg text-lg px-2 py-2 text-center me-2 mb-2 mr-2 uppercase"
            onClick={() => {
              setGenrePlaylist(null);
              setPickedGenre(null);
            }}
          >
            <div className="flex items-center justify-between">
              <div>{pickdGenre}</div>
              <div className="w-[20px]">{" "}</div>
              <div className="text-xs">X</div>
            </div>
          </button>
        )}
      </h2>
      <TrackList
        tracks={data}
        pickTrack={pickTrack}
        setRequestedTrack={setRequestedTrack}
        mode={mode}
        showChatlist={showChatlist}
        setGenrePlaylist={setGenrePlaylist}
        genre={genre}
      />
    </div>
  );
};

export default App;

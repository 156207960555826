import React, { useEffect, useState, useRef } from "react";
import { auth, db } from "../firebase-config";
import { signOut } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { Jukebox } from "./Jukebox";
import { AutoPlay } from "./AutoPlay";
import { Users } from "./Users";
import { ChatList } from "./ChatList";
import { HiddenChatList } from "./HiddenChatList";
import { LoadingAnimation } from "./LoadingAnimation";
import { Chat } from "./Chat";
import { SideBarMenu } from "./SideBarMenu";
import { Avatar } from "./Avatar";
import Cookies from "universal-cookie";
import { useIsVisible } from "../hooks/useIsVisible";
const cookies = new Cookies();
export const AppLayout = ({
  appMode,
  children,
  isAuth,
  setIsAuth,
  setIsInChat,
  setChatId,
  setIsLaunched,
  funcProp,
  client,
  appLayoutMode,
}) => {
  const ref = useRef();
  const [isInChat2, setIsInChat2] = useState(null);
  const [isInUsers, setIsInUsers] = useState(null);
  const [isInJukebox, setIsInJukebox] = useState(null);
  const [isInAutoPlay, setIsInAutoPlay] = useState(null);
  const [isInHiddenChat, setIsInHiddenChat] = useState(null);
  const [isInChatList, setInChatList] = useState(true);
  const [chatId2, setChatId2] = useState(null);
  const [chatType, setChatType] = useState(null);
  const [trackDetails, setTrackDetails] = useState(null);
  const [isOpen, setIsopen] = useState(false); // sniff if view is desktop or mobile
  const [avatars, setAvatars] = useState({});
  const [userObject, setUserObject] = useState({});
  const [chatUsers, setChatUsers] = useState([]);
  const [showLoadingAni, setShowLoadingAni] = useState(false);
  const [clientObj, setClientObj] = useState(null);
  const [openJukeboxPlaylist, setOpenJukeboxPlaylist] = useState(false);
  // const [appMode, setAppMode] = useState(appMode)

  const [mode, setMode] = useState("light");
  const ToggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };

  const isLaunched = useIsVisible(ref);

  const signUserOut = async () => {
    //cookies.remove("auth-token");
    //cookies.remove("auth-token", {path: "/", domain: "muuzbox.com"})
    removeCookieWithPromise("auth-token", { path: "/" })
      .then(async () => {
        // console.log("Cookie removal complete. Now performing another task.");
        // Perform another task
        await signOut(auth);
        setIsAuth(false);
        setIsInChat(false);
        setIsInChat2(false);
        setIsInUsers(false);
      })
      .catch((error) => {
        console.error("Failed to remove cookie:", error);
      });
  };

  const removeCookieWithPromise = (cookieName, options = {}) => {
    return new Promise((resolve, reject) => {
      try {
        cookies.remove(cookieName, options); // Remove the cookie
        resolve(); // Resolve the promise
      } catch (error) {
        console.error("Error removing the cookie:", error);
        reject(error); // Reject the promise if there's an error
      }
    });
  };

  const closeAllPanels = () => {
    setShowLoadingAni(false);
    setIsInChat(false);
    setIsInChat2(false);
    setIsInUsers(false);
    setIsInJukebox(false);
    setIsInAutoPlay(false);
    setIsInHiddenChat(false);
    setIsopen(false);
  };
  const pickedChat = (chatId, type, trackDetails, users) => {
    closeAllPanels();
    setIsInChat2(true);
    setChatId(chatId);
    setChatId2(null);
    setChatId2(chatId);
    setChatType(type);
    setTrackDetails(trackDetails);
    setChatUsers(users);
  };
  const loadingAni = () => {
    closeAllPanels();
    setShowLoadingAni(true);
  };
  const openUsersPanel = () => {
    closeAllPanels();
    setIsInUsers(true);
  };
  const openJukeboxPanel = () => {
    closeAllPanels();
    if (window.location.hostname === "admin.muuzbox.com") {
      setIsInAutoPlay(true);
    } else {
      setIsInJukebox(true);
      setOpenJukeboxPlaylist(!openJukeboxPlaylist);
    }
  };
  const openAutoPlayPanel = () => {
    closeAllPanels();
    setIsInAutoPlay(true);
  };
  const openHiddenChatPanel = () => {
    closeAllPanels();
    setShowLoadingAni(false);
    setIsInHiddenChat(true);
  };
  const refreshChatList = () => {
    // setInChatList(false);
    // setTimeout(() => {
    //   setInChatList(true);
    // }, 300);
  };
  const sbm = (message) => {
    if (message === "sign out") signUserOut();
  };

  useEffect(() => {
    // console.log("appMode", appMode);
  }, [appMode]);

  useEffect(() => {
    // Add listener to update styles
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (e) =>
        onSelectMode(e.matches ? "dark" : "light")
      );

    // Setup dark/light mode for the first time
    onSelectMode(
      window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light"
    );

    // find the client uid
    const getClientObj = async () => {
      if (client) {
        const docRef = doc(db, "clients", client);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const Obj = {
            id: docSnap.id,
            clientId: docSnap.data().clientId,
            defaultPlaylist: docSnap.data().defaultPlaylist,
            avatarImage: docSnap.data().avatarImage,
          };
          setClientObj(Obj);
        }
      }
    };
    getClientObj();

    // Remove listener
    return () => {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .removeEventListener("change", () => {});
    };
  }, []);

  React.useEffect(() => {
    if (isLaunched) {
      //callbackFn(); // Trigger a function when the div is visible on view port
      setIsLaunched(true);
    }
  }, [isLaunched]);

  const onSelectMode = (mode) => {
    setMode(mode);
    if (mode === "dark") {
      // document.body.classList.add('dark-mode')
      document.documentElement.setAttribute("data-theme", "dark");
      // console.log("dark mode");
    } else {
      // document.body.classList.remove('dark-mode')
      document.documentElement.setAttribute("data-theme", "light");
      // console.log("light mode");
    }
  };

  useEffect(() => {
    if (isAuth !== undefined || isAuth !== false) {
      setTimeout(async () => {
        // actually go get the user detail from the user collection rather than from the auth
        // when anonymous reloads it loses its displayName and photo.
        const avObj = {};
        try {
          await getUserDetails(auth?.currentUser?.uid).then((userObj) => {
            avObj[auth?.currentUser?.uid] = userObj;
            setUserObject(userObj);
            setAvatars(avObj);
          });
        } catch (err) {
          console.log("error", err);
        }
      }, 3000);
    }
  }, [isAuth]);

  const getUserDetails = async (uid) => {
    const docRef = doc(db, "users", uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return {
        displayName: docSnap.data().displayName,
        photoURL: docSnap.data().photoURL,
        email: docSnap.data().email,
        nameChanged: docSnap.data().nameChanged || false,
        id: docSnap.id,
        bio: docSnap.data().bio,
      };
    }
  };

  if (!auth.currentUser) {
    <div>LOADING...</div>;
  }

  const UserName = () => {
    <div className="text-xs text-white">{auth?.currentUser?.displayName}</div>;
  };

  return (
    <div ref={ref} className="App bg-white dark:bg-black">
      <div className="flex flex-row justify-between max-w-full">
        <SideBarMenu
          openUsersPanel={openUsersPanel}
          openJukeboxPanel={openJukeboxPanel}
          openAutoPlayPanel={openAutoPlayPanel}
          openHiddenChatPanel={openHiddenChatPanel}
          sbm={sbm}
          funcProp={funcProp}
          loadingAni={loadingAni}
          appMode={appMode}
          appLayoutMode={appLayoutMode}
        />
        <div className="flex flex-row items-center">
          <div
            onClick={() => {
              appLayoutMode("newlayout");
            }}
          >
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 9V18C4 19.1046 4.89543 20 6 20H9M4 9V6C4 4.89543 4.89543 4 6 4H18C19.1046 4 20 4.89543 20 6V9M4 9H9M20 9H9M20 9V14.5M9 9V14.5M9 20H18C19.1046 20 20 19.1046 20 18V14.5M9 20V14.5M9 14.5H20"
                stroke="#FFFFFF"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div
            onClick={() => {
              appLayoutMode("newlayout");
            }}
          >
            3
            {/* <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 9V18C4 19.1046 4.89543 20 6 20H10M4 9V6C4 4.89543 4.89543 4 6 4H18C19.1046 4 20 4.89543 20 6V9M4 9H10M20 9V18C20 19.1046 19.1046 20 18 20H10M20 9H10M10 9V20"
                stroke="#FFFFFF"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg> */}
          </div>
        </div>
        <div class="muuzbox-title">
          MUUZBOX<span>v 0.5.6</span>
        </div>
        {/* <div class="close-muuzbox-btn ion-close"></div> */}
      </div>
      <div className="app-container max-w-full">
        {children}
        {isAuth && (
          <>
            <section className="h-screen overflow-hidden flex items-center justify-center max-w-full">
              <div className="flex h-screen antialiased text-gray-800 dark:text-white w-full">
                <div className="flex flex-row h-full w-full overflow-hidden">
                  <div
                    className={`flex flex-col py-8 pr-2 w-7 md:w-40 flex-shrink-0 sidebar ${
                      isOpen === true ? "active" : ""
                    }`}
                    style={{ height: "calc(100dvh - 162px)" }}
                  >
                    <div className="flex flex-col md:flex-row items-center justify-center h-12 w-full my-2">
                      <div className="flex flex-row justify-center">
                        <div
                          onClick={openJukeboxPanel}
                          className="muuzbox-wc-logo"
                        ></div>
                      </div>
                    </div>
                    <UserName />
                    <div className="flex flex-col flex-auto items-center justify-between bg-indigo-100 mt-4  w-full py-6 px-6 rounded-lg mobile-chatlist panel-fade relative">
                      <div>
                        {/* <SideBarMenu
                          openUsersPanel={openUsersPanel}
                          openJukeboxPanel={openJukeboxPanel}
                          openAutoPlayPanel={openAutoPlayPanel}
                          openHiddenChatPanel={openHiddenChatPanel}
                          sbm={sbm}
                          funcProp={funcProp}
                          loadingAni={loadingAni}
                          appMode={appMode}
                          appLayoutMode={appLayoutMode}
                        /> */}
                        <div className="flex justify-center h-16 w-16 md:h-20 md:w-20 rounded-full border overflow-hidden text-center items-center hidden">
                          {isAuth !== undefined && (
                            <Avatar
                              avObj={avatars}
                              uid={auth?.currentUser?.uid}
                            />
                          )}
                        </div>
                        <div class="text-center items-center text-xs font-black rac-padding text-white">
                          Requests &amp; Chat
                        </div>
                        <div
                          className={`flex flex-col space-y-1 mt-4 -mx-2 ebuebu wc-chatlist  ${
                            isOpen === true ? "hidden" : ""
                          }`}
                        >
                          {isInChatList && clientObj && (
                            <ChatList
                              pickedChat={pickedChat}
                              thinLayout={true}
                              loadingAni={loadingAni}
                              clientObj={clientObj}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {isInChat2 && clientObj && (
                    <Chat
                      newchatId={chatId2}
                      chatId={chatId2}
                      chatType={chatType}
                      trackDetails={trackDetails}
                      openJukeboxPanel={openJukeboxPanel}
                      userObj={userObject}
                      chatUsers={chatUsers}
                      refreshChatList={refreshChatList}
                      clientObj={clientObj}
                    />
                  )}
                  {isInUsers && <Users refreshChatList={refreshChatList} />}
                  {isInJukebox && clientObj && (
                    <Jukebox
                      userObj={userObject}
                      clientObj={clientObj}
                      openJukeboxPlaylist={openJukeboxPlaylist}
                    />
                  )}
                  {isInAutoPlay && <AutoPlay userObj={userObject} />}
                  {isInHiddenChat && (
                    <HiddenChatList
                      pickedChat={pickedChat}
                      thinLayout={false}
                      loadingAni={loadingAni}
                    />
                  )}
                  {/* showLoadingAni */}
                  {showLoadingAni && <LoadingAnimation />}
                </div>
              </div>
            </section>
          </>
        )}
      </div>
    </div>
  );
};

import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import useEmblaCarousel from "embla-carousel-react";
import {
  NextButton,
  PrevButton,
  usePrevNextButtons,
} from "./EmblaCarouselArrowButtons";
import AutoScroll from "embla-carousel-auto-scroll";
import { Message_data } from "../context/context";
import cn from "classnames";

export const JukeboxList2 = ({
  jukebox,
  setRequestedTrack,
  pickTrack,
  viewNextTrack,
  showChatlist
}) => {
  // console.log('updated',jukebox);
  const csp = useRef(null);
  const [jukeboxList, setJukeboxList] = useState([]);
  const [currentSongId, setCurrentSongId] = useState(null);

  const { message, setMessage } = useContext(Message_data);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const [widerJbCarousel, setWiderJbCaroursel] = useState(showChatlist)

useEffect(() => {
  console.log('showChatlist', showChatlist)
  setWiderJbCaroursel(showChatlist)
}, [showChatlist]);


  useEffect(() => {
    const playerControlData = async () => {
      if (message) {
        const data = JSON.parse(message);
        if (data.triggerScroll) {
          csp.current.scrollIntoView({ behavior: "smooth", block: "end" });
        }
      }
    };
    playerControlData();
  }, [message]);

  useEffect(() => {
    // console.log('updated jukebox', jukebox)
    setJukeboxList(jukebox);
  }, [jukebox]);

  /////////////////////////////////////////////////////////////////////////

  /*

  // when I work out what I'm going about the slider

  const options =
    jukebox.length < 3
      ? { loop: false, dragFree: false }
      : { loop: true, dragFree: true };

  const otheroptions =
      jukebox.length < 3
        ? {
          playOnInit: false,
          stopOnInteraction: false,
          startDelay: 500,
          speed: 1,
        }
        : {
          playOnInit: true,
          stopOnInteraction: false,
          startDelay: 500,
          speed: 1,
        };

  */


  const options = { loop: true, dragFree: true };
  const [emblaRef, emblaApi] = useEmblaCarousel(options, [
    AutoScroll({
      playOnInit: true,
      stopOnInteraction: false,
      startDelay: 500,
      speed: 1,
    }),
  ]);
  const [isPlaying, setIsPlaying] = useState(false);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  // const onButtonAutoplayClick = useCallback(
  //   (callback) => {
  //     const autoScroll = emblaApi?.plugins()?.autoScroll;
  //     if (!autoScroll) return;

  //     const resetOrStop =
  //       autoScroll.options.stopOnInteraction === false
  //         ? autoScroll.reset
  //         : autoScroll.stop;

  //     resetOrStop();
  //     callback();
  //   },
  //   [emblaApi]
  // );

  // const toggleAutoplay = useCallback(() => {
  //   const autoScroll = emblaApi?.plugins()?.autoScroll;
  //   if (!autoScroll) return;

  //   const playOrStop = autoScroll.isPlaying()
  //     ? autoScroll.stop
  //     : autoScroll.play;
  //   playOrStop();
  //   // setTimeout(() => {
  //   //   // playOrStop()
  //   //   console.log('go again')

  //   //   const playOrStopx = autoScroll.isPlaying()
  //   //     ? autoScroll.play
  //   //     : autoScroll.play
  //   //   playOrStopx()
  //   // }, 3000)
  // }, [emblaApi]);

  useEffect(() => {
    const autoScroll = emblaApi?.plugins()?.autoScroll;
    if (!autoScroll) return;

    setIsPlaying(autoScroll.isPlaying());
    emblaApi
      .on("autoScroll:play", () => setIsPlaying(true))
      .on("autoScroll:stop", () => {
        console.log("what is this");
        setIsPlaying(false);

        // setTimeout(() => {
        //   console.log('go again')
        //   const playOrStopx = autoScroll.isPlaying()
        //     ? autoScroll.play
        //     : autoScroll.play
        //   playOrStopx()
        // }, 3000)
      })
      .on("reInit", () => setIsPlaying(autoScroll.isPlaying()));
  }, [emblaApi]);

  return (
    <>
      {/* <div className="flex flex-row mt-4">
        <button
          type="button"
          class="text-black w-2/4 bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-yellow-300 dark:focus:ring-yellow-800 font-medium rounded-lg text-lg px-2 py-2 text-center me-2 mb-2 mr-2"
          onClick={() => {
            //   const element = document.getElementById("currentsongplaying");
            //   if (!element) return;
            csp.current.scrollIntoView({ behavior: "smooth", block: "end" });
          }}
        >
          <div className="flex items-center justify-center">
            <div>PLAYING NOW</div>
            <svg
              style={{ color: "white" }}
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              {" "}
              <path
                d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"
                fill="yellow"
              ></path>{" "}
            </svg>
          </div>
        </button>
        <button
          type="button"
          class="text-black w-2/4 bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-lg px-2 py-2 text-center me-2 mb-2 mr-2"
          onClick={(e) => {
            viewNextTrack(e);
          }}
        >
          <div className="flex items-center justify-center">
            <div>VIEW NEXT SONG</div>
            <svg
              style={{ color: "white" }}
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              {" "}
              <path
                d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"
                fill="yellow"
              ></path>{" "}
            </svg>
          </div>
        </button>
      </div> */}
      {/* <h2 className="text-white uppercase mt-2">Individual Tracks</h2> */}

      <div className={`embla ${
                        showChatlist === true ? "" : "activated"
                      }`}>
        <div className="embla__viewport" ref={emblaRef}>
          <div className="embla__container rounded-2xl"> {/*widerJbCarousel*/}
            {jukeboxList &&
              jukeboxList.map((item, i) => (
                <div className="embla__slide rounded-2xl bg-gray-100 h-full p-4 m-4 panel-fade" key={i}>
                  <div className="embla__slide__number">
                    {/* <span>{i + 1}</span> */}

                    <div
                      className="flex items-center py-4 px-3"
                      key={i}
                      onClick={(event) => {
                        setRequestedTrack(item);
                        pickTrack(event, item, i);
                      }}
                    >
                      <span className="text-gray-100 text-lg font-black font-medium mr-1">
                        {i + 1}.
                      </span>
                      <img
                        className={"rounded-full mr-1"}
                        src={item.coverart}
                        style={{ width: "48px", height: "48px" }}
                        alt={item.track}
                      />
                      <div className="flex-1" style={{ width: "50%" }}>
                        <div className="text-lg font-medium text-white max-h-[32px] overflow-hidden">
                          {item.artist}
                        </div>
                        <div className="text-gray-100 max-h-[32px] overflow-hidden text-base">{item.track} </div>
                        <p className="text-white text-base">{item.genre} </p>
                        {item.currentSong === "true" && (
                          <div
                            id="currentsongplaying"
                            ref={csp}
                            className="flex flex-row justify-center"
                          >
                            <div className="playani"></div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

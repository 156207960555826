import React, { useState, useEffect, useContext, useRef } from "react";
import { Message_data } from "../context/context";
import { db, auth } from "../firebase-config";
import {
  doc,
  collection,
  getDoc,
  addDoc,
  orderBy,
  serverTimestamp,
  onSnapshot,
  query,
  deleteDoc,
  where,
  writeBatch,
  getDocs,
  setDoc,
} from "firebase/firestore";

export const CurrentTrackView = ({
  clientObj,
  trackObj,
  setShowCurrentTrackModal,
  currentTrack,
  initialTime = 5,
}) => {
  const [timeLeft, setTimeLeft] = useState(initialTime);
  const timerRef = useRef(null);

  // Function to reset countdown
  const resetTimer = () => {
    clearInterval(timerRef.current); // Clear previous timer
    setTimeLeft(initialTime); // Reset time
    startTimer(); // Restart timer
  };

  // Start countdown
  const startTimer = () => {
    timerRef.current = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 1) {
          clearInterval(timerRef.current); // Stop at 0
          setShowCurrentTrackModal(false);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    startTimer();
    return () => {
      clearInterval(timerRef.current); // Cleanup on unmount
    }
  }, []);

  return (
    <div className="bg-white text-black divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 muuz-edituser-panel">
      <div>
        <div className="flex justify-between text-white">
          <div>&npsp;</div>
          <div className="text-black font-black">
            <h1>CURRENT TRACK</h1>
            {/* <h2 className="text-2xl font-bold mb-4">Countdown: {timeLeft}s</h2> */}
          </div>
          <button
            onClick={() => {
              setShowCurrentTrackModal(false);
            }}
            className="block text-center flex justify-end px-4 py-2 text-black hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white"
          >
            <div>X</div>
          </button>
        </div>
        <hr className="mt-4" />
        <div className="flex flex-wrap justify-left mt-2 h-20 overflow">
          <div className="w-full lg:w-1/2">
            {currentTrack.title}

            <div className="text-center">
              <button
                onClick={() => {
                  // setIsOpen(!isOpen);
                }}
                className="block w-full text-center px-4 py-2 hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white muuzmenuitem"
              >
                <div className="fadeIn rounded-lg">
                  <img
                    className="rounded-lg coverart-ani"
                    src={currentTrack.coverart}
                    alt={"cover art for " + currentTrack.track}
                  />
                </div>

                <div>
                  <strong>{currentTrack.artist}</strong>
                </div>
                <div>{currentTrack.track}</div>
              </button>
            </div>

            <button
              onClick={resetTimer}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Reset Timer
            </button>

          </div>
        </div>
      </div>
    </div>
  );
};

// export default NextTrackVote;

import React, { useState, useEffect } from "react";
import { db, auth } from "../firebase-config";
import {
  collection,
  onSnapshot,
  query,
  doc,
  getDoc,
  setDoc,
  serverTimestamp,
  updateDoc,
  where,
  orderBy,

  // orderBy, // eventualy order by name
} from "firebase/firestore";
import { Avatar } from "./Avatar";
import VerticalCarousel from "./VerticalCarousel";
import { useAuth } from "../context/AuthUserContext";

import "../styles/Chat.scss";

export const ChatList = ({ pickedChat, thinLayout, loadingAni, clientObj, resetTimer }) => {
  const { currentUser, loading } = useAuth();
  const [chatlist, setChatlist] = useState([]);
  const [prevChatlist, setPrevChatlist] = useState([]);
  const [avatars, setAvatars] = useState({});
  const [requestList, setRequestList] = useState([]);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [isLoading, setIsLoading] = useState(true);
  const [theCurrentChatId, setTheCurrentChatId] = useState("");

  useEffect(() => {
    // change all of this to auth context so its not an issue again
    setTimeout(() => {
      let gotCurrentChatId = "";
      new Promise((resolve, reject) => {
        gotCurrentChatId = getCurrentChat();
        if (gotCurrentChatId !== "") {
          setTheCurrentChatId(gotCurrentChatId)
          resolve(gotCurrentChatId);
        } else reject();
      }).then((results) => {
        getDJChatList(results);
      });
    }, 2000);
  }, []);

  const openChat = async (e, chatId, type, users) => {
    e.preventDefault();
    let trackDetailsIndex = requestList.findIndex(
      (item) => item.chatId === chatId
    );

    let prepareOpenChat = new Promise(function (resolve, reject) {
      resolve("Promise resolved");
    });

    prepareOpenChat
      .then(async () => {
        console.log('type', type)
        console.log('chatId', chatId)
        console.log('auth.currentUser.uid', auth.currentUser.uid)
        if (type === "private") {
          const docUnreadRef = doc(
            db,
            "users",
            auth.currentUser.uid,
            "chats",
            chatId
          );
          const unreadAmountUpdate = { unreadAmount: 0 };
          try {
            await updateDoc(docUnreadRef, { ...unreadAmountUpdate });
          } catch (err) {
            console.log("update readAmount error", err);
          }
        } else {
          console.log('this is a not private this is probably djchat', type)
          console.log('no update for now')
        }
      })

      .then(async () => {
        const usersWithphotos = [];
        users.forEach(async (user, i) => {
          const userPhotoRef = doc(db, "users", user.id);
          const docSnap = await getDoc(userPhotoRef);
          usersWithphotos[i] = user;
          if (docSnap.exists()) {
            usersWithphotos[i].photoURL = docSnap.data().photoURL;
          }
        });
        return usersWithphotos;
      })

      .then(async (usersWithphotos) => {
        const docRef = doc(db, "users", auth.currentUser.uid);
        const docSnap = await getDoc(docRef);
        const currentChat = { currentChat: chatId };
        if (docSnap.exists()) {
          try {
            await updateDoc(docRef, { ...currentChat });
          } catch (err) {
            console.log(err);
          }
        }

        setTimeout(() => {
          pickedChat(
            chatId,
            type,
            requestList[trackDetailsIndex],
            usersWithphotos
          );
          getChatList(chatId, requestList);
        }, 3000);
      });
  };

  const getCurrentChat = async () => {
    const docRef = doc(db, "users", auth.currentUser.uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data().currentChat;
    }
  };

  const getDJChatList = async (results) => {
    const requestFeedbackRef = collection(db, "users", clientObj.clientId, "djchats");
    const queryMessages = query(
      requestFeedbackRef,
      where("uid", "==", auth.currentUser.uid),
      orderBy("createdAt")
    );

    const unsuscribe = onSnapshot(queryMessages, (snapshot) => {
      let requestFeedback = [];
      snapshot.forEach((doc) => {
        if (!(doc.data().status && doc.data().status === "closed")) {
          const requestData = {
            chatId: doc.id,
            chatLength: 0,
            createdAt: doc.data().createdAt,
            id: doc.id,
            type: "djchat",
            unreadAmount: doc.data().unreadAmount,
            usersx: [{ id: clientObj.clientId }],
            users: [
              {
                displayName: "MuuzBox DJ",
                email: "muuzbox@muuzbox.com",
                id: clientObj.clientId,
                photoURL: "https://thisisobah.com/img/obah-logo.png",
              },
              {
                displayName: "MuuzBox DJ",
                email: "muuzbox@muuzbox.com",
                id: clientObj.clientId,
                photoURL: "https://thisisobah.com/img/obah-logo.png",
              },
            ],
            coverart: doc.data().request.coverart,
            track: doc.data().request.track,
          };
          requestFeedback.push(requestData);
        }
      });
      // console.log("requestFeedback", requestFeedback);
      setRequestList(requestFeedback);
      getChatList(results, requestFeedback);
    });
    return () => unsuscribe();
  };

  const getChatList = async (currentChatId, requestFeedback) => {
    if (auth.currentUser) {
      const chatRef = collection(db, "users", auth.currentUser.uid, "chats");
      const queryChats = query(
        chatRef /*,
      where("room", "==", room),
      orderBy("createdAt")*/
      );
      let missingChat = {};
      const unsuscribe = onSnapshot(queryChats, (snapshot) => {
        let chats = [];
        let avatars = [];
        snapshot.forEach((doc) => {
          const chatUsers = doc.data().users;
          missingChat = doc.data();
          //ignore hidden chats
          if (doc.data().status && doc.data().status === "hidden") {
            // console.log("users* doc.id", doc.id);
            // console.log("users* doc.data().status:", doc.data().status);
            // console.log('users* doc.data()', doc.data().users[1].id);
          } else {
            avatars.push(chatUsers[1].id);
            chats.push({ ...doc.data(), id: doc.id });
            // get the length of each chat
          }
        });
        new Promise((resolve, reject) => {
          const avatarObj = {};
          let i = 0;
          // search for these uids and the photoURL that goes with them
          avatars.forEach(async (uid) => {
            i++;
            const docRef = doc(db, "users", uid);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
              avatarObj[uid] = {
                displayName: docSnap.data().displayName,
                photoURL: docSnap.data().photoURL,
                email: docSnap.data().email,
              };
            } else {
              // some random image maybe an ai generated icon and displayname
            }
          });
          if (i >= avatars.length) resolve(avatarObj);
          else reject();
        })
          .then(function (avatarObj) {
            return new Promise((resolve, reject) => {
              setTimeout(() => resolve(setAvatars(avatarObj)), 500);
            });
          })
          .then((res) => {
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                const newChatlist = [];
                chats.forEach(async (item, i) => {
                  const lclRef = doc(
                    db,
                    "users",
                    auth.currentUser.uid,
                    "updatewatchList",
                    item.chatId
                  );
                  const lclDoc = await getDoc(lclRef);
                  if (lclDoc.exists()) {
                    let unreadAmount;
                    if (item.chatLength === undefined) {
                      unreadAmount = 0;
                    } else {
                      let x = item.chatLength - lclDoc.data().chatLength;
                      if (x < 0) unreadAmount = 0;
                      else
                        unreadAmount =
                          item.chatLength - lclDoc.data().chatLength;
                    }

                    item.unreadAmount = unreadAmount;
                    newChatlist.push(item);
                  } else {
                    // lclDoc doesn't exists yet so let insert on
                    const updatewatchListRef2 = doc(
                      db,
                      "users",
                      auth.currentUser.uid,
                      "updatewatchList",
                      item.chatId
                    );

                    try {
                      const thisChat2 = {
                        chatId: item.chatId,
                        updatedAt: serverTimestamp(),
                        chatLength: 0,
                      };
                      const updatewatchListDocSnap = await getDoc(
                        updatewatchListRef2
                      );
                      if (updatewatchListDocSnap.exists()) {
                        await setDoc(
                          doc(
                            db,
                            "users",
                            auth.currentUser.uid,
                            "updatewatchList",
                            item.chatId
                          ),
                          thisChat2
                        );
                      } else {
                        await setDoc(
                          doc(
                            db,
                            "users",
                            auth.currentUser.uid,
                            "updatewatchList",
                            item.chatId
                          ),
                          thisChat2
                        );
                      }
                    } catch (err) {
                      console.log(err);
                    }
                  }
                });
                if (newChatlist.length >= 0) resolve(newChatlist);
                else reject();
              }, 500);
            });
          })
          .then((newChatlist) => {
            if (prevChatlist.length > 0) {
              // has previous chat
              setPrevChatlist(newChatlist);
            } else {
              setPrevChatlist(newChatlist);
            }

            return new Promise((resolve, reject) => {
              setTimeout(() => {
                const i = newChatlist.findIndex(
                  (item) => item.id === theCurrentChatId
                );
                if (i !== -1) newChatlist[i].unreadAmount = 0;

                let combinedList;
                if (requestFeedback) {
                  combinedList = newChatlist.concat(requestFeedback);
                } else combinedList = newChatlist;
                // turn off loading animation
                setIsLoading(false)
                resolve(setChatlist(combinedList));
              }, 500);
            });
          });
      });
      return () => unsuscribe();
    }
  };

  const LoadingChatList = () => {
    // console.log('looading')
    return(<div className="flex flex-col h-full overflow-x-auto mb-4">
      <div className="flex flex-col h-full">
        <div className="grid grid-cols-12 gap-y-2">
          <div class="muuzbox-loader-logo-sm3 fadeIn">
            <img src="img/muuzbox-logo.png" alt="Muuzbox logo" />
            <div class="muuzbox-loader-blob"></div>
          </div>
        </div>
      </div>
    </div>)
  }

  return (
    <>
      {currentUser && thinLayout ? (
        <>
          {isLoading && <LoadingChatList />}

          {!isLoading && (
            <VerticalCarousel
              avatars={avatars}
              data={chatlist}
              openChat={openChat}
              loadingAni={loadingAni}
              resetTimer={resetTimer}
            />
          )}
        </>
      ) : (
        <>
          {chatlist.map((chat, i) => (
            <button
              className={
                "flex flex-row items-center hover:bg-gray-100 rounded-xl p-2 " +
                  chat.unreadAmount >
                0
                  ? "unread"
                  : ""
              }
              key={chat.id}
              onClick={(e) => { openChat(e, chat.chatId, chat.type); resetTimer()}}
            >
              <div className="flex items-center justify-center h-8 w-8 rounded-full border overflow-hidden bg-indigo-500 flex-shrink-0">
                {chat.type === "private" ? (
                  <Avatar avObj={avatars} uid={chat.users[1].id} />
                ) : (
                  <div>
                    <img
                      src={chat.coverart}
                      alt={chat.track}
                      title={chat.track}
                    />
                  </div>
                )}
              </div>
              <div className="ml-2 text-sm font-semibold">
                {chat.users[0].id === auth.currentUser.uid ? (
                  <div
                    style={{ width: "100px", overflowX: "hidden" }}
                    className="text-left text-ellipsis"
                  >
                    {chat.users[1].displayName === "" ? (
                      <>{chat.users[1].email}</>
                    ) : (
                      <>{chat.users[1].displayName}</>
                    )}
                  </div>
                ) : (
                  <div
                    style={{ width: "100px", overflowX: "hidden" }}
                    className="text-left text-ellipsis"
                  >
                    {chat.track}
                  </div>
                )}
              </div>
              <div className="flex items-center justify-center ml-auto text-xs text-white bg-red-500 h-4 w-4 rounded leading-none">
                {chat.unreadAmount}
              </div>
            </button>
          ))}
        </>
      )}
    </>
  );
};

import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import { Message_data } from "../context/context";
import { db, auth } from "../firebase-config";
import {
  doc,
  collection,
  getDoc,
  addDoc,
  orderBy,
  serverTimestamp,
  onSnapshot,
  query,
  deleteDoc,
  where,
  writeBatch,
  getDocs,
  setDoc,
  updateDoc,
  getCountFromServer,
} from "firebase/firestore";

export const NextTrackVote = ({
  nextSongObjectVotes,
  userObj,
  clientObj,
  placeholderAvatar,
  trackObj,
  setShowNextTrackModal,
  playlistId,
  setCurrentPendingLength,
  currentPendingLength,
  initialTime = 5,
}) => {
  const [downvotes, setDownvotes] = useState(0);
  const [totalvotes, setTotalvotes] = useState(0);
  const [voteRefresh, nextVoteRefresh] = useState(false);
  const [downvotedColour, setDownvotedColour] = useState("none");
  const [upvotedColour, setUpvotedColour] = useState("none");
  const { message, setMessage } = useContext(Message_data);
  const [nextTrackObj, setNextTrackObj] = useState(trackObj);
  const [totalVotes, setTotalVotes] = useState(0);
  const [upObj, setUpObj] = useState({});
  const [downObj, setDownObj] = useState({});
  const [messages, setMessages] = useState([]);
  const [updatePendingList, setUpdatePendingList] = useState([]);

  useEffect(() => {
    // get the pending list tracks

    const chatsMessgesRef = collection(
      db,
      "users",
      clientObj.clientId,
      "pendingList"
    );
    let pendingList = [];
    const queryTracks = query(chatsMessgesRef, orderBy("ordinal"));
    const unsuscribe = onSnapshot(queryTracks, (snapshot) => {
      snapshot.forEach((doc) => {
        pendingList.push(Object.assign({ ...doc.data(), id: doc.id }));
      });
    });
    setMessages(pendingList);
    setTimeout(() => {
      const m = pendingList;
      nextTrackObj.default = true;
      m.push(Object.assign(nextTrackObj));
      setMessages(m);
    }, 1000);

    return () => {
      unsuscribe();
    };
  }, [clientObj.clientId, nextTrackObj, updatePendingList]);

  useEffect(() => {
    const init = async () => {
      // collate votes for all pending tracks

      const votesRef = collection(
        db,
        "users",
        clientObj.clientId,
        "pendingListVotes"
      );

      const voteUpObj = {};
      const voteDownObj = {};
      const votes = [];
      const unsubscribe = onSnapshot(votesRef, (querySnapshot) => {
        let changes = querySnapshot.docChanges();
        for (let change of changes) {
          if (change.doc.data().votedAt !== null) {
            let trackId = change.doc.data().trackId;
            votes.push(change.doc.data());
            if (change.doc.data().voted === "up") {
              if (voteUpObj[trackId]) {
                let vote = voteUpObj[trackId];
                vote++;
                voteUpObj[trackId] = vote;
              } else {
                voteUpObj[trackId] = 1;
              }
            } else {
              if (voteDownObj[trackId]) {
                let vote = voteDownObj[trackId];
                vote++;
                voteDownObj[trackId] = vote;
              } else {
                voteDownObj[trackId] = 1;
              }
            }
          }
        }
        setTimeout(() => {
          setTotalVotes(votes);
          setDownObj(voteDownObj);
          setUpObj(voteUpObj);
        }, 1000);
      });

      return () => unsubscribe();
    };
    init();
  }, [clientObj.clientId]);

  useEffect(() => {
    const init = async () => {
      const nextDocRef = doc(
        db,
        "users",
        clientObj.clientId,
        "nextSong",
        "NEXTSONG"
      );
      const docNextSongSnap = await getDoc(nextDocRef);
      if (docNextSongSnap.exists()) {
        const voted = docNextSongSnap.data().voted || [];
        console.log("voted", voted.length);
        setTotalvotes(voted.length);
        const uv = docNextSongSnap.data().upvotes || 0;
        // setUpvotes(uv);
        const dv = docNextSongSnap.data().downvotes || 0;
        setDownvotes(dv);
      }
    };
    init();
  }, [clientObj.clientId, upvotedColour, downvotedColour, voteRefresh]);

  useEffect(() => {
    const playerControlData = async () => {
      if (message) {
        const data = JSON.parse(message);
        if (data.newtrackupdate) {
          const newtrackupdate = JSON.parse(data.newtrackupdate);
          console.log("newtrackupdate", newtrackupdate);
          setNextTrackObj(newtrackupdate);
          nextVoteRefresh(!voteRefresh);
        }
        //
        if (data.pendingListUpdate) {
          const pendingListUpdate = JSON.parse(data.pendingListUpdate);
          console.log("pendingListUpdate", pendingListUpdate);
          setUpdatePendingList(pendingListUpdate);
          nextVoteRefresh(!voteRefresh);
        }
      }
    };
    playerControlData();
  }, [message]);

  const upvote = async (track) => {
    // to determine if this user has already voted for this track lets look it up on the pendingListVotes collection
    // if the trackId and the voter/auth.currentUser.uid match then this voter cannot vote on this track again
    const notVoted = await checkVotingMatches(track.id, auth.currentUser.uid);
    if (notVoted) {
      const refDoc = collection(
        db,
        "users",
        clientObj.clientId,
        "pendingListVotes"
      );
      try {
        await addDoc(refDoc, {
          trackId: track.id,
          voter: auth.currentUser.uid,
          voted: "up",
          votedAt: serverTimestamp(),
        });

        ////////////////////////////////////////////////////

        // return newprod;
        // lets inform all the other people who voted for this track in a chat
        // send an individual chat to each person found and then highlight the
        // chat button in 2nd layout so people know theres a message waiting

        console.log("**==** track.id", track.id);
        console.log("**==** auth.currentUser.uid", auth.currentUser.uid);
        console.log("**==** totalVotes", totalVotes);

        // const alsoVotedList = totalVotes.map((item) => {
        //   if (item.trackId === track.id && item.voted === "up") {
        //     return item;
        //   }
        // });

        // eslint-disable-next-line array-callback-return
        const alsoVotedList = totalVotes.filter((item) => {
          console.log("item", item);
          if (item.trackId === track.id && item.voted === "up") {
            // can just shoot off a context message right here to message  the one found
            // get user object from item.voter
            addToFriendsList(item.voter, track);
            console.log("other voter", item.voter);
            return item;
          }
        });

        console.log("**==** alsoVotedList", alsoVotedList);

        ////////////////////////////////////////////////////
      } catch (error) {
        console.error("Error adding document: ", error);
      }
    } else {
      alert("you have already voted on this track");
    }
  };

  // people who voted in common
  const addToFriendsList = async (uid, track) => {
    console.log("**==** voter uid", uid);
    // e.preventDefault();

    let user = {};
    const docRef = doc(db, "users", uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      user = {
        id: uid,
        displayName: docSnap.data().displayName,
        photoURL: docSnap.data().photoURL,
        email: docSnap.data().email,
      };
      console.log("**==** got voter user details", user);
      // const isthisemail = docSnap.data().email;
    }

    const chatRef = collection(db, "users", auth.currentUser.uid, "chats");
    try {
      console.log(
        "**==**  find out if this user is already in current user chats",
        user
      );
      const { docs } = await getDocs(chatRef);
      let chatFound = null;
      console.log("**==** docs", docs);
      docs.forEach((doc) => {
        const userList = doc.data().users;
        const i = userList.findIndex(
          (chatuser) =>
            chatuser.id === user.id && chatuser.id !== auth.currentUser.uid
        );
        if (i !== -1) {
          chatFound = doc.id;
        }
      });
      if (chatFound !== null) {
        console.log("**==** chat to be opened", chatFound);
        // just send the user a message since the chat already exists
        const messagesRef4 = collection(db, "chats", chatFound, "messages");
        console.log(
          "**==** send them the first chats messages using chatFound",
          chatFound
        );
        await addDoc(messagesRef4, {
          text: "We voted for the same song.",
          votedFor: track,
          createdAt: serverTimestamp(),
          user: auth.currentUser.displayName,
          uid: auth.currentUser.uid,
        });
        // get the total number of messages here
        const snapshot = await getCountFromServer(messagesRef4);
        console.log('count: ', snapshot.data().count);
        const messageLength = snapshot.data().count

        // make sure the unread storage is updated
        setTimeout(async () => {
          // updateWatchList(chatFound, user.id);

          //now go look for all the other chat users personal chat listand update the updatedAt field
          // so it can be snapped on in ChatList

          const otherUsersRef = doc(
            db,
            "users",
            auth.currentUser.uid,
            "chats",
            chatFound
          );
          const otherUsersDoc = await getDoc(otherUsersRef);
          if (otherUsersDoc.exists) {
            const otherUserList = otherUsersDoc.data().users;
            otherUserList.forEach(async (item, i) => {
              try {
                const updatedAt = {
                  chatLength: messageLength,
                };
                const otherItemRef = doc(
                  db,
                  "users",
                  item.id,
                  "chats",
                  chatFound
                );
                const otherItem = await getDoc(otherItemRef);
                if (otherItem.exists()) {
                  await updateDoc(otherItemRef, { ...updatedAt });
                }
              } catch (err) {
                console.log(err);
              }
            });
          }
        }, 500);
      } else {
        // make a new chat featuring this picked user and the current logged in user
        const chatStoreRef = collection(db, "chats");
        const newChatStoreId = await addDoc(chatStoreRef, {
          createdAt: serverTimestamp(),
          type: "private", // or group
        });
        const chatRefRef = doc(
          db,
          "users",
          auth.currentUser.uid,
          "chats",
          newChatStoreId.id
        );
        console.log("**==** new chat newChatStoreId.id", newChatStoreId.id);
        console.log("**==** put this user and current user into it", user);
        await setDoc(chatRefRef, {
          createdAt: serverTimestamp(),
          type: "private", // or group
          newchat: 1,
          users: [
            {
              id: auth.currentUser.uid || "",
              email: auth.currentUser.email || "",
              displayName: auth.currentUser.displayName || "",
            },
            {
              id: user.id || "",
              email: user.email || "",
              displayName: user.displayName || "",
            },
          ],
          chatId: newChatStoreId.id,
        });

        const chatRefRef2 = doc(
          db,
          "users",
          user.id,
          "chats",
          newChatStoreId.id
        );
        console.log("**==** do the same in the other users chats table", user);
        await setDoc(chatRefRef2, {
          createdAt: serverTimestamp(),
          type: "private", // or group
          users: [
            {
              id: user.id || "",
              email: user.email || "",
              displayName: user.displayName || "",
            },
            {
              id: auth.currentUser.uid || "",
              email: auth.currentUser.email || "",
              displayName: auth.currentUser.displayName || "",
            },
          ],
          chatId: newChatStoreId.id,
        });

        const messagesRef4 = collection(
          db,
          "chats",
          newChatStoreId.id,
          "messages"
        );
        console.log(
          "**==** send them the first chats messages using newChatStoreId.id",
          newChatStoreId.id
        );
        await addDoc(messagesRef4, {
          text: "We voted for the same song.",
          votedFor: track,
          createdAt: serverTimestamp(),
          user: user.displayName, //auth.currentUser.displayName || auth.currentUser.email,
          uid: user.id, //auth.currentUser.uid,
        });

        // setTimeout(async () => {
        //   await updateWatchList(1, newChatStoreId.id);
        // }, 500);
      }

      // refresh the chatlist component
      // refreshChatList();
    } catch (error) {
      console.log(error);
    }
  };

  const updateWatchList = async (chatId, userId) => {
    const updatewatchListRef2 = doc(
      db,
      "users",
      userId,
      "updatewatchList",
      chatId
    );

    try {
      const thisChat2 = {
        chatId: chatId,
        updatedAt: serverTimestamp(),
        //chatLength: msgsLength,
      };
      const updatewatchListDocSnap = await getDoc(updatewatchListRef2);
      // get the message length

      if (updatewatchListDocSnap.exists()) {
        console.log('updatewatchListDocSnap.data()', updatewatchListDocSnap.data())
        thisChat2.chatLength = parseInt(updatewatchListDocSnap.data().chatLength) + 1
        await setDoc(
          doc(db, "users", userId, "updatewatchList", chatId),
          thisChat2
        );
      } else {
        console.log('updatewatchListDocSnap.data()', updatewatchListDocSnap.data())
        thisChat2.chatLength = 1;
        await setDoc(
          doc(db, "users", userId, "updatewatchList", chatId),
          thisChat2
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const downvote = async (track) => {
    const notVoted = await checkVotingMatches(track.id, auth.currentUser.uid);
    // check if this vote is minus 3
    // if it is minus 3 then change

    const dv = downObj[track.id] || 0;
    const uv = upObj[track.id] || 0;

    console.log("downObj[track.id]+1", dv);
    console.log("upObj[track.id]", uv);

    if (dv + 1 - uv >= 3) {
      console.log("this track should be taken out of list");
      bumpThisTrack(track);
    } else if (notVoted) {
      const refDoc = collection(
        db,
        "users",
        clientObj.clientId,
        "pendingListVotes"
      );
      try {
        await addDoc(refDoc, {
          trackId: track.id,
          voter: auth.currentUser.uid,
          voted: "down",
          votedAt: serverTimestamp(),
        });
        // return newprod;
      } catch (error) {
        console.error("Error adding document: ", error);
      }
    } else {
      alert("you have already voted on this track");
    }
  };

  const checkVotingMatches = async (trackId, uid) => {
    console.log("totalVotes", totalVotes);
    if (totalVotes.length > 0) {
      console.log("uid", uid);
      const idx = totalVotes.findIndex(
        (item) => item.trackId === trackId && item.voter === uid
      );
      if (idx !== -1) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const bumpThisTrack = async (track) => {
    // update a record that the autoplay can read and therefore take this track out
    // of pendinglist and/or being next to play (if its a default next track it has
    // to be passed over,  if its a request it can be taken out of jukebox list in autoplay)
    //
    if (track.default) {
      console.log("this is a default track");

      const skippableRef = collection(
        db,
        "users",
        clientObj.clientId,
        "skippable"
      );

      const data = {
        ...track,
        createAt: serverTimestamp(),
      };

      try {
        await addDoc(skippableRef, data);
      } catch (error) {
        console.error("Error adding document: ", error);
      }
    } else {
      console.log("this isi not a default track");
      // simply delete this track and its votes
      // from pendingList

      const init = async () => {
        // update pending collection for this user with newPending
        const plv = [];
        console.log("this hsould be an array: oldPending", oldPending);
        if (oldPending.length > 0) {
          oldPending.forEach((item) => {
            console.log("delete item", item);
            if (item) {
              const ref = doc(
                db,
                `users`,
                clientObj.clientId,
                "pendingList",
                item.id
              );
              deleteDoc(ref);
              plv.push(item);
            }
          });

          // now get rid of plv
          console.log("plv", plv);
          console.log("plv length", plv.length);
          if (plv.length > 0) {
            plv.forEach(async (plvitem) => {
              console.log("plv item", plvitem);

              const collectionRef = collection(
                db,
                "users",
                clientObj.clientId,
                "pendingListVotes"
              );
              const q = query(
                collectionRef,
                where("trackId", "==", plvitem.id)
              );

              const querySnapshot = await getDocs(q);
              const batch = writeBatch(db);

              querySnapshot.forEach((doc) => {
                batch.delete(doc.ref);
              });

              await batch.commit();
              console.log("Documents deleted successfully");
            });
          }
          const ref2 = doc(
            db,
            `users`,
            clientObj.clientId,
            "deleteRequest",
            "DELETEREQUEST"
          );
          setDoc(ref2, {
            muuzboxDelSong: track,
            updatedAt: serverTimestamp(),
          });
        }
      };
      const oldPending = [];
      oldPending.push(track);
      init();
    }
  };

  const [timeLeft, setTimeLeft] = useState(initialTime);
  const timerRef = useRef(null);

  // Function to reset countdown
  const resetTimer = () => {
    clearInterval(timerRef.current); // Clear previous timer
    setTimeLeft(initialTime); // Reset time
    startTimer(); // Restart timer
  };

  // Start countdown
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const startTimer = useCallback(() => {
    timerRef.current = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 1) {
          clearInterval(timerRef.current); // Stop at 0
          setShowNextTrackModal(false);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  });

  useEffect(() => {
    startTimer();
    return () => {
      clearInterval(timerRef.current); // Cleanup on unmount
    };
  }, [startTimer]);

  return (
    <div className="bg-white text-black divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 muuz-edituser-panel">
      <div>
        <div className="flex justify-between text-white">
          <div>&npsp;</div>
          <div className="text-black font-black">
            <h1>NEXT TRACK</h1>
          </div>
          <button
            onClick={() => {
              setShowNextTrackModal(false);
            }}
            className="block text-center flex justify-end px-4 py-2 text-black hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white"
          >
            <div>X</div>
          </button>
        </div>
        <hr className="mt-4" />
        <div className="flex flex-wrap justify-left mt-2 h-20 overflow">
          <div className="w-full lg:w-1/2">
            {messages.map((item, i) => (
              <div className="flex flex-row justify-between mb-1" key={i}>
                <img
                  src={item.coverart}
                  alt="cover art"
                  className="w-full lg:w-1/2 rounded-full mr-2"
                  style={{ width: "64px", height: "64px" }}
                />
                <div className="mt-8 text-sm">
                  <div>{item.artist}</div>
                  <div>{item.track}</div>
                </div>
                <div className="flex flex-row justify-evenly mt-8">
                  <div className="flex flex-col">
                    <svg
                      onClick={(e) => {
                        upvote(item);
                        resetTimer();
                      }}
                      width="20px"
                      height="20px"
                      viewBox="0 0 24 24"
                      fill={
                        upObj[item.id] && upObj[item.id] > 0
                          ? "#008000"
                          : "#FFFFFF"
                      }
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.99997 20H17.1919C17.9865 20 18.7058 19.5296 19.0243 18.8016L21.8323 12.3833C21.9429 12.1305 22 11.8576 22 11.5816V11C22 9.89543 21.1045 9 20 9H13.5L14.7066 4.5757C14.8772 3.95023 14.5826 3.2913 14.0027 3.00136V3.00136C13.4204 2.7102 12.7134 2.87256 12.3164 3.3886L8.41472 8.46082C8.14579 8.81044 7.99997 9.23915 7.99997 9.68024V20ZM7.99997 20H2V10H7.99997V20Z"
                        stroke="#000000"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <div>{upObj[item.id]}</div>
                  </div>

                  <div className="flex flex-col">
                    <svg
                      onClick={(e) => {
                        downvote(item);
                        resetTimer();
                      }}
                      width="20px"
                      height="20px"
                      viewBox="0 0 24 24"
                      fill={
                        downObj[item.id] && downObj[item.id] > 0
                          ? "#FF0000"
                          : "#FFFFFF"
                      }
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.99997 4H17.1919C17.9865 4 18.7058 4.47039 19.0243 5.19836L21.8323 11.6167C21.9429 11.8695 22 12.1424 22 12.4184V13C22 14.1046 21.1045 15 20 15H13.5L14.7066 19.4243C14.8772 20.0498 14.5826 20.7087 14.0027 20.9986V20.9986C13.4204 21.2898 12.7134 21.1274 12.3164 20.6114L8.41472 15.5392C8.14579 15.1896 7.99997 14.7608 7.99997 14.3198V14M7.99997 4H2V14H7.99997M7.99997 4V14"
                        stroke="#000000"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <div>{downObj[item.id]}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* {showSuggestions && (
          <>
            <textarea
              placeholder="suggestion"
              className="flex text-gray-800 dark:text-gray-800 border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 pt-4 pr-4 h-40  mt-2 mb-2"
              onChange={handleEditUser}
              name="bio"
            />
          </>
        )} */}
      </div>
    </div>
  );
};

// export default NextTrackVote;

import React, { useState, useEffect, useRef, useContext } from "react";
import { Message_data } from "../../context/context";
import { auth, db, db2, storage } from "../../firebase-config";
import {
  collection,
  onSnapshot,
  query,
  doc,
  getDoc,
  addDoc,
  setDoc,
  deleteDoc,
  updateDoc,
  serverTimestamp,
  orderBy,
  writeBatch,
  getDocs,
} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useDropzone } from "react-dropzone";
import "./JukeboxRequests.scss";
import msgs from "./data.json";
import { Avatar } from "./Avatar";
import MuuzTubeSearch from "../MuuzTubeSearch";
import CurrentPlaylist from "./CurrentPlaylist";
import AccordionTrackList from "../AccordionTrackList";
import { LoadingAnimation } from "../LoadingAnimation";
import Settings from "../Settings";
import { NLMFileReader } from "../NLMFileReader2";

function App({ filedata, loadAutoPlaylist }) {
  const chatbase = useRef(null);
  const { message, setMessage } = useContext(Message_data);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [jukebox, setJukebox] = useState([]);
  const [requstList, setRequstList] = useState([]);
  const [requestShow, setRequestShow] = useState(false);
  const [responseModal, setResponseModal] = useState(false);
  const [acceptedSong, setAcceptedSong] = useState({
    id: "5fD03BaD7INyhJMne2gfLdPAKbQ2",
  });
  const [chatmessage, setChatmessage] = useState("");
  const [avatars, setAvatars] = useState({});
  const [messages, setMessages] = useState([]);
  const [chatId, setChatId] = useState("5fD03BaD7INyhJMne2gfLdPAKbQ2");
  const [cannedResponseShow, setCannedResponseShow] = useState(true);
  const [cannedResponse, setCannedResponse] = useState(msgs);
  const [requesterListShow, setRequesterListShow] = useState(false);
  const [platform, setPlatform] = useState("");
  const [showYoutubeSearch, setShowYoutubeSearch] = useState(false);
  const [createPlaylistName, setCreatePlaylistName] = useState(false);
  const [playlistName, setPlaylistName] = useState("");
  const [showPlaylists, setShowPlaylists] = useState(false);
  const [playlists, setPlaylists] = useState([]);
  const [editPlaylistModal, setEditPlaylistModal] = useState(false);
  const [currentPlaylist, setCurrentPlaylist] = useState([]);
  const [playlistId, setPlaylistId] = useState(null);
  const [showLoadingAni, setShowLoadingAni] = useState(false);
  const [acceptedSongs, setAcceptedSongs] = useState([]);
  const [groupedTracks, setGroupedTracks] = useState([]);
  const [autoRequests, setAutoRequests] = useState(true);
  const [lastRequest, setLastRequest] = useState({});
  const [settingsModal, setSettingsModal] = useState(false);
  const [fileName, setFileName] = useState("");
  const [percent, setPercent] = useState(0);
  const [NLMFile, setNLMFile] = useState(null);
  const [local, setLocal] = useState(false);
  const [showDeleteConfirmed, setShowDeleteConfirmed] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  const [skippableList, setSkippableList] = useState([]);

  useEffect(() => {
    setPlatform(window.navigator.userAgentData.platform);

    const requestFeedbackRef = collection(
      db,
      "users",
      auth.currentUser.uid,
      "djchats"
    );
    const queryMessages = query(requestFeedbackRef, orderBy("createdAt")); //, where("createdAt", "<", serverTimestamp()), orderBy("createdAt"));
    const unsuscribe = onSnapshot(queryMessages, (snapshot) => {
      let requestFeedback = [];
      snapshot.forEach((doc) => {
        if (!(doc.data().status && doc.data().status === "closed")) {
          requestFeedback.push({ ...doc.data(), id: doc.id });
        }
      });
      setRequstList(requestFeedback);
      // console.log('1. requestFeedback', requestFeedback);
      if (
        autoRequests &&
        requestFeedback[requestFeedback.length - 1]?.request?.id !==
          lastRequest?.id
      ) {
        // add to qued requests
        // if current last is not equal to the last one tested
        console.log("2. requestFeedback", requestFeedback);
        setLastRequest(requestFeedback[requestFeedback.length - 1].request);

        // end the user name and uid so they can be pointed out as the requester in the pedning list
        const r = requestFeedback[requestFeedback.length - 1].request;
        r.requester = requestFeedback[requestFeedback.length - 1].user;
        r.uid = requestFeedback[requestFeedback.length - 1].uid;
        let autorequest = {
          insertTrack: JSON.stringify(r),
        };
        setMessage(JSON.stringify(autorequest));
      }
    });
    return () => unsuscribe();
  }, [autoRequests, platform, setMessage]);

  useEffect(() => {
    const delSongRef = collection(
      db,
      "users",
      auth.currentUser.uid,
      "deleteRequest"
    );
    
    const unsubscribe = onSnapshot(delSongRef, (snapshot) => {
      let ds = [];
      snapshot.forEach((doc) => {
        ds.push(doc.data().muuzboxDelSong);
      });
      const delTrack = { delTrack: JSON.stringify(ds[0]) };
      setMessage(JSON.stringify(delTrack));
    });

    return () => {
      unsubscribe();
    };
  }, [setMessage]);

  useEffect(() => {
    const trackSkipRef = collection(
      db,
      "users",
      auth.currentUser.uid,
      "skippable"
    );

    // const queryMessages = query(trackSkipRef, orderBy("createdAt")); //, where("createdAt", "<", serverTimestamp()), orderBy("createdAt"));
    const unsubcribe = onSnapshot(trackSkipRef, (snapshot) => {
      let skipList = [];
      snapshot.forEach((doc) => {
        console.log('skippable from db', doc.data().id)
        console.log('skippable from db', doc.data().artist)
        console.log('skippable from db', doc.data().track)
        skipList.push({ ...doc.data(), skippableId: doc.data().id });

        let skipTrack = {
          skipTrack: JSON.stringify({ ...doc.data() }),
        };
        setMessage(JSON.stringify(skipTrack));
      });
      // console.log('skipList', skipList)
      setSkippableList(skipList);
    });

    setTimeout(() => {
      // skippableList.  roll troug this and delete every record in it

      if (skippableList.length > 0) {
        skippableList.forEach(async (plvitem) => {
          console.log("plv item", plvitem);

          const collectionRef = collection(
            db,
            "users",
            auth.currentUser.uid,
            "skippable"
          );
          // const q = query(
          //   collectionRef,
          //   where("trackId", "==", plvitem.id)
          // );

          const querySnapshot = await getDocs(collectionRef);
          const batch = writeBatch(db);

          querySnapshot.forEach((doc) => {
            batch.delete(doc.ref);
          });

          await batch.commit();
          console.log("Skippable documents deleted successfully");
        });
      }
    }, 1000);

    return () => {
      unsubcribe();
    };
  }, [setMessage, skippableList]);

  useEffect(() => {
    setGroupedTracks(acceptedSongs);
  }, [acceptedSongs]);

  useEffect(() => {
    const playlistsRef = collection(
      db,
      "users",
      auth.currentUser.uid,
      "playlists"
    );
    const queryPlaylists = query(playlistsRef, orderBy("createdAt"));
    const unsubscribe = onSnapshot(queryPlaylists, (snapshot) => {
      const playlists = [];
      snapshot.forEach((doc) => {
        playlists.push(doc.data());
      });
      setPlaylists(playlists);
    });
    return () => unsubscribe();
  }, [showPlaylists]);

  useEffect(() => {
    const chatsMessgesRef = collection(
      db,
      "users",
      auth.currentUser.uid,
      "djchats",
      chatId,
      "messages"
    );
    const queryMessages = query(chatsMessgesRef, orderBy("createdAt"));
    const unsubscribe = onSnapshot(queryMessages, (snapshot) => {
      let messagesx = [];
      let avatars = [];

      snapshot.forEach((doc) => {
        // create an avatar list that can be referred to when the chatlist is rendered
        avatars.push(doc.data().uid);
        messagesx.push(Object.assign({ ...doc.data(), id: doc.id }));
      });

      new Promise(function (resolve, reject) {
        const avatarObj = {};
        const uniq = [...new Set(avatars)];
        let i = 0;
        // search for these uids and the photoURL that goes with them
        uniq.forEach(async (uid) => {
          i++;
          const docRef = doc(db, "users", uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            avatarObj[uid] = {
              displayName: docSnap.data().displayName,
              photoURL: docSnap.data().photoURL,
              email: docSnap.data().email,
            };
          } else {
            // some random image maybe an ai generated icon and displayname
            avatarObj[uid] = {
              displayName: docSnap.data().displayName || null,
              photoURL: docSnap.data().photoURL || null,
            };
          }
        });
        if (i >= uniq.length) resolve(avatarObj);
        else reject();
      })
        .then(function (avatarObj) {
          return new Promise((resolve, reject) => {
            setTimeout(() => resolve(setAvatars(avatarObj)), 300);
          });
        })
        .then(function (result) {
          setMessages(messagesx);
        });
    });
    return () => unsubscribe();
  }, [chatId]);

  useEffect(() => {
    const getData = async () => {
      if (filedata) {
        const obj = JSON.parse(filedata);
        setJukebox(obj.jukebox);
      }
    };
    getData();
  }, [filedata]);

  const setRequestedTrackSetter = (x) => {
    // console.log('x',x);
  };

  const pickTrack = (x) => {
    // console.log("x", x);
  };

  const vtp = (video) => {
    // console.log("JukeboxRequests:");
    // console.log("video", video);
  };

  const handleShowPlaylist = async (e) => {
    e.preventDefault();
    closeAllTabs();
    setShowPlaylists(true);
  };

  const handJukeboxGoLive = async (e) => {
    e.preventDefault();
    // send this whole list to firebase so it can be picked up by the  chabout Web Component App
    setRequestShow(false);

    // const docRefx = doc(db2, "jukebox2", "CURRENTJUKEBOX");
    // await setDoc(docRefx, {
    //   updatedAt: serverTimestamp(),
    //   jukeboxList: jukebox,
    // });

    const docRef = doc(db2, "jukebox", "CURRENTJUKEBOX");
    await setDoc(docRef, {
      updatedAt: serverTimestamp(),
      jukeboxList: jukebox,
    });
    alert("New Jukebox Saved");
  };

  const handlePlaylistCreation = (e) => {
    closeAllTabs();
    setCreatePlaylistName(true);
  };

  const handleCheckRequests = async (e) => {
    e.preventDefault();
    closeAllTabs();
    setRequestShow(true);
  };

  const { acceptedFiles, getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    onDrop: (acceptedFiles) => {
      setUploadedFiles(acceptedFiles);
    },
  });
  const files = acceptedFiles.map((f) => <li key={f.name}>{f.path}</li>);

  const acceptRequest = (e, song) => {
    e.preventDefault();
    setMessages("");
    setChatId(song.id);

    // create a list of users who have requested this song from current playlist showing
    setAcceptedSong(song);
    let a = acceptedSongs;
    a.push(song);
    setAcceptedSongs(a);

    setTimeout(() => {
      console.log("acceptedSongs", acceptedSongs);
    }, 300);

    // find users in the list
    // group them by song
    // accordian style
    // before showing the modal get the requester list ready
    const requesters = requstList.filter((req) => req.id === song.id);

    setResponseModal(!responseModal);
  };

  const handleChat = async (event) => {
    let value = event.target.value;
    setChatmessage(value);
  };

  const handlePlaylist = async (event) => {
    let value = event.target.value;
    setPlaylistName(value);
  };

  const updateDjChatLength = async (chatId) => {
    const docRef = doc(db, "users", auth.currentUser.uid, "djchats", chatId);
    const unreadAmount = { unreadAmount: messages.length + 1 };
    await updateDoc(docRef, {
      ...unreadAmount,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    chatbase.current.scrollIntoView({ behavior: "smooth", block: "end" });
    const docRef = collection(
      db,
      "users",
      auth.currentUser.uid,
      "djchats",
      chatId,
      "messages"
    );
    await addDoc(docRef, {
      text: chatmessage,
      createdAt: serverTimestamp(),
      user: "DJ OBAH",
      uid: "5fD03BaD7INyhJMne2gfLdPAKbQ2",
    });
    updateDjChatLength(chatId);
    setChatmessage("");
    setMessages("");

    setTimeout(() => {
      chatbase.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }, 500);
  };

  const handleSubmitPlaylist = async (event) => {
    event.preventDefault();
    setPlaylistName("");
    closeAllTabs();
    // create new playlist in firehost
    // record the playist id in the users playlist profile array
    // playlists will still be able to be broken down in the genre lists like the nlm jukebox lists

    const docRef = collection(db, "playlists");
    const newpl = await addDoc(docRef, {
      playlistName: playlistName,
      createdAt: serverTimestamp(),
      user: auth.currentUser.displayName,
      uid: auth.currentUser.uid,
    });

    await setDoc(
      doc(db, "users", auth.currentUser.uid, "playlists", newpl.id),
      {
        id: newpl.id,
        playlistName: playlistName,
        createdAt: serverTimestamp(),
      }
    );
  };

  const addCannedResponse = async (e, canned) => {
    e.preventDefault();

    if (canned.text === "Your song is next") {
      // insert this song into the playlist right afer whats currently playing
      // whats the song
      // send acceptedsong to context message
      const insertTrack = { insertTrack: JSON.stringify(acceptedSong.request) };
      setMessage(JSON.stringify(insertTrack));
    }

    // need to link this to the actual logged in user its there requests
    const docRef = collection(
      db,
      "users",
      auth.currentUser.uid,
      "djchats",
      chatId,
      "messages"
    );
    await addDoc(docRef, {
      text: canned.text,
      createdAt: serverTimestamp(),
      user: "DJ OBAH",
      uid: "5fD03BaD7INyhJMne2gfLdPAKbQ2",
    });
    updateDjChatLength(chatId);
    setChatmessage("");
    setMessages("");
    //setCannedResponseShow(false);

    setTimeout(() => {
      chatbase.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }, 500);
  };

  const searchYoutube = async (e, song) => {
    e.preventDefault();
    setShowYoutubeSearch(!showYoutubeSearch);
    // target the container and switch to youtube search form
  };

  const rejectRequest = async (e, song) => {
    e.preventDefault();
    const docRef = doc(db, "users", auth.currentUser.uid, "djchats", song.id);
    const status = { status: "closed" };
    await updateDoc(docRef, {
      ...status,
    });
  };

  const closeAllTabs = async () => {
    setRequestShow(false);
    setCreatePlaylistName(false);
    setShowPlaylists(false);
  };

  const updateCurrentPlaylist = async (adminplaylist) => {
    setEditPlaylistModal(!editPlaylistModal);
    setShowLoadingAni(true);
    // setTimeout(() => {
    //   editPlaylist(null, playlistId);
    // }, 2000);

    let playlist = [];
    const playlistRef = collection(db, "playlists", adminplaylist.id, "tracks");
    const queryList = query(playlistRef, orderBy("ordinal"));
    onSnapshot(queryList, (snapshot) => {
      playlist = [];
      snapshot.forEach(async (docItem) => {
        playlist.push({
          id: docItem.id,
          track: docItem.data().track,
          genre: docItem.data().genre,
          artist: docItem.data().artist,
          coverart: docItem.data().coverart,
          url: docItem.data().url,
          youTubeId: docItem.data().youTubeId,
          filename: docItem.data().filename,
        });

        // const trackDocRef = doc(db, "tracks", docItem.data().id);
        // const docSnap = await getDoc(trackDocRef);
        // if (docSnap.exists()) {
        //   playlist.push({
        //     id: docSnap.id,
        //     track: docSnap.data().track,
        //     genre: docSnap.data().genre,
        //     artist: docSnap.data().artist,
        //     coverart: docSnap.data().coverart,
        //     url: docSnap.data().url,
        //     youTubeId: docSnap.data().youTubeId,
        //   });
        // }
      });
      setTimeout(() => {
        setShowLoadingAni(false);
        setLocal(false);
        setCurrentPlaylist(playlist);
        const loadingAni = { loadingAni: JSON.stringify(false) };
        setMessage(JSON.stringify(loadingAni));
      }, 3000);
    });
    setPlaylistId(adminplaylist);
  };

  const editPlaylist = (e, adminplaylist) => {
    // playlist name = adminplaylist.playlistName
    console.log("editPlaylist");
    if (e) e.preventDefault();
    setEditPlaylistModal(true);
    let playlist = [];
    const playlistRef = collection(db, "playlists", adminplaylist.id, "tracks");
    const queryList = query(playlistRef, orderBy("ordinal")); //, where("createdAt", "<", serverTimestamp()), orderBy("createdAt"));
    const unsuscribe = onSnapshot(queryList, (snapshot) => {
      playlist = [];
      snapshot.forEach(async (docItem) => {
        playlist.push({
          id: docItem.id,
          track: docItem.data().track,
          genre: docItem.data().genre,
          artist: docItem.data().artist,
          coverart: docItem.data().coverart,
          url: docItem.data().url,
          youTubeId: docItem.data().youTubeId,
          filename: docItem.data().filename,
        });

        // const trackDocRef = doc(db, "tracks", docItem.data().id);
        // const docSnap = await getDoc(trackDocRef);
        // if (docSnap.exists()) {
        //   playlist.push({
        //     id: docSnap.id,
        //     track: docSnap.data().track,
        //     genre: docSnap.data().genre,
        //     artist: docSnap.data().artist,
        //     coverart: docSnap.data().coverart,
        //     url: docSnap.data().url,
        //     youTubeId: docSnap.data().youTubeId,
        //   });
        // }
      });
      setTimeout(() => {
        setShowLoadingAni(false);
        setLocal(false);
        setCurrentPlaylist(playlist);
        const loadingAni = { loadingAni: JSON.stringify(false) };
        setMessage(JSON.stringify(loadingAni));
      }, 3000);
    });
    setPlaylistId(adminplaylist);
    return () => unsuscribe();
  };

  let lastID = 0;
  let displayName = "DJ OBAH";
  let email = "thisisobah@gmail.com";

  const launchPlaylist = async (e, playlistId, playlistName) => {
    e.preventDefault();

    // before anything, clear the pending playlist out
    const collectionRef = collection(
      db,
      "users",
      auth.currentUser.uid,
      "pendingList"
    ); // Change to your collection name
    // const q = query(collectionRef, where("status", "==", "expired")); // Adjust your query

    const querySnapshot = await getDocs(collectionRef);
    const batch = writeBatch(db);

    querySnapshot.forEach((doc) => {
      batch.delete(doc.ref);
    });

    await batch.commit();
    console.log("Documents deleted successfully");

    let playlist = [];
    const playlistRef = collection(db, "playlists", playlistId.id, "tracks");
    const queryList = query(playlistRef, orderBy("ordinal")); //, where("createdAt", "<", serverTimestamp()), orderBy("createdAt"));
    const unsuscribe = onSnapshot(queryList, (snapshot) => {
      playlist = [];
      snapshot.forEach(async (docItem) => {
        //const trackDocRef = doc(db, "tracks", docItem.data().id);
        const trackDocRef = doc(
          db,
          "playlists",
          playlistId.id,
          "tracks",
          docItem.data().id
        );
        const docSnap = await getDoc(trackDocRef);
        if (docSnap.exists()) {
          // console.log('docSnap.data().filename', docSnap.data().filename)
          playlist.push({
            id: docSnap.id,
            track: docSnap.data().track,
            genre: docSnap.data().genre,
            artist: docSnap.data().artist,
            coverart: docSnap.data().coverart,
            url: docSnap.data().url,
            youTubeId: docSnap.data().youTubeId,
            filename: docSnap.data().filename,
          });
        }
      });
      setTimeout(() => {
        setShowLoadingAni(false);
        loadAutoPlaylist(playlistId.id, playlist, playlistName);
      }, 1500);
    });
    setPlaylistId(playlistId);
    return () => unsuscribe();
  };

  const handleAutoInsert = () => {
    setAutoRequests(!autoRequests);
  };

  const handleSettings = (e) => {
    e.preventDefault();
    setSettingsModal(true);
  };

  const handleFile = (file) => {
    // console.log('file', file);

    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result;
      // console.log(text)
      // alert(text)
    };
    reader.readAsText(file); //(e.target.files[0])

    setFileName(file.name);
    // if (!file) {
    //   alert("Please upload an image first!");
    // }

    // const storageRef = ref(
    //   storage,
    //   `muuzbox/nlmplaylists/${auth.currentUser.uid}/${file.name}`
    // );

    // // progress can be paused and resumed. It also exposes progress updates.
    // // Receives the storage reference and the file to upload.
    // const uploadTask = uploadBytesResumable(storageRef, file);

    // uploadTask.on(
    //   "state_changed",
    //   (snapshot) => {
    //     const percent = Math.round(
    //       (snapshot.bytesTransferred / snapshot.totalBytes) * 100
    //     );

    //     // update progress
    //     setPercent(percent);
    //   },
    //   (err) => console.log(err),
    //   () => {
    //     // download url
    //     getDownloadURL(uploadTask.snapshot.ref).then((url) => {
    //       console.log(url);
    //       // put this url in the clients object for this user
    //       // then display it
    //       setNLMFile(url);
    //     });
    //   }
    // );
  };

  // const insertLocalPlaylist = (pl) => {
  //   setLocal(true);
  //   setCurrentPlaylist(pl);
  //   console.log('pl', pl)
  //   console.log('this should now be saved...but it is not');
  //   forceUpdate();
  // };

  useEffect(() => {
    const playerControlData = async () => {
      if (message) {
        // const data = JSON.parse(message);
        // if (data.jbrLoadAni) {
        //   const jbrLoadAni = JSON.parse(data.jbrLoadAni);
        //   setLocal(true);
        // }
      }
    };
    playerControlData();
  }, [message]);

  const deletePlaylist = (e, deletePlaylistItem) => {
    e.preventDefault();
    console.log("delete playlist", deletePlaylistItem);
    setDeleteItem(deletePlaylistItem);
    setShowDeleteConfirmed(true);
  };

  const deletePlaylistConfirmed = async (e, deletePlaylistItem) => {
    e.preventDefault();
    console.log("confirmed delete playlist", deletePlaylistItem);
    // delete this playlist, go to the user and delete it there too not the tracks
    // deletePlaylistItem.id  <--- playlist id
    const plDocRef = doc(db, "playlists", deletePlaylistItem.id);
    await deleteDoc(plDocRef);
    const uplDocRef = doc(
      db,
      "users",
      auth.currentUser.uid,
      "playlists",
      deletePlaylistItem.id
    );
    await deleteDoc(uplDocRef);
    setShowDeleteConfirmed(!showDeleteConfirmed);
  };

  return (
    <div className="JukeboxRequests">
      <section className="request-container">
        {platform === "macOS" && <div id="eebz"></div>}
      </section>
      <section className="request-container">
        <div id="dragged" className="" {...getRootProps()}>
          <section className="request-container">
            <div className="bg-white shadow-md rounded-md overflow-hidden">
              <div
                className="absolute absolute top-0 right-0 text-black cursor-pointer"
                onClick={(e) => handleSettings(e)}
              >
                <svg
                  width="20px"
                  height="20px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.65 3L9.93163 3.53449L9.32754 5.54812L7.47651 4.55141L6.5906 4.68143L4.68141 6.59062L4.55139 7.47652L5.5481 9.32755L3.53449 9.93163L3 10.65V13.35L3.53449 14.0684L5.54811 14.6725L4.55142 16.5235L4.68144 17.4094L6.59063 19.3186L7.47653 19.4486L9.32754 18.4519L9.93163 20.4655L10.65 21H13.35L14.0684 20.4655L14.6725 18.4519L16.5235 19.4486L17.4094 19.3185L19.3186 17.4094L19.4486 16.5235L18.4519 14.6724L20.4655 14.0684L21 13.35V10.65L20.4655 9.93163L18.4519 9.32754L19.4486 7.47654L19.3186 6.59063L17.4094 4.68144L16.5235 4.55142L14.6725 5.54812L14.0684 3.53449L13.35 3H10.65ZM10.4692 6.96284L11.208 4.5H12.792L13.5308 6.96284L13.8753 7.0946C13.9654 7.12908 14.0543 7.16597 14.142 7.2052L14.4789 7.35598L16.7433 6.13668L17.8633 7.25671L16.644 9.52111L16.7948 9.85803C16.834 9.9457 16.8709 10.0346 16.9054 10.1247L17.0372 10.4692L19.5 11.208V12.792L17.0372 13.5308L16.9054 13.8753C16.8709 13.9654 16.834 14.0543 16.7948 14.1419L16.644 14.4789L17.8633 16.7433L16.7433 17.8633L14.4789 16.644L14.142 16.7948C14.0543 16.834 13.9654 16.8709 13.8753 16.9054L13.5308 17.0372L12.792 19.5H11.208L10.4692 17.0372L10.1247 16.9054C10.0346 16.8709 9.94569 16.834 9.85803 16.7948L9.52111 16.644L7.25671 17.8633L6.13668 16.7433L7.35597 14.4789L7.2052 14.142C7.16597 14.0543 7.12908 13.9654 7.0946 13.8753L6.96284 13.5308L4.5 12.792L4.5 11.208L6.96284 10.4692L7.0946 10.1247C7.12907 10.0346 7.16596 9.94571 7.20519 9.85805L7.35596 9.52113L6.13666 7.2567L7.25668 6.13667L9.5211 7.35598L9.85803 7.2052C9.9457 7.16597 10.0346 7.12908 10.1247 7.0946L10.4692 6.96284ZM14.25 12C14.25 13.2426 13.2426 14.25 12 14.25C10.7574 14.25 9.75 13.2426 9.75 12C9.75 10.7574 10.7574 9.75 12 9.75C13.2426 9.75 14.25 10.7574 14.25 12ZM15.75 12C15.75 14.0711 14.0711 15.75 12 15.75C9.92893 15.75 8.25 14.0711 8.25 12C8.25 9.92893 9.92893 8.25 12 8.25C14.0711 8.25 15.75 9.92893 15.75 12Z"
                    fill="#080341"
                  />
                </svg>
              </div>

              <h2 className="text-xl font-semibold text-gray-800 pb-2">
                MUUZBOX CRATE
              </h2>
              <button
                onClick={(e) => handleShowPlaylist(e)}
                type="button"
                class="text-black bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-yellow-300 dark:focus:ring-yellow-800 font-medium rounded-lg text-lg px-2 py-2 text-center me-2 mb-2 mr-2"
              >
                Show Playlists
              </button>
              <button
                onClick={(e) => handlePlaylistCreation(e)}
                type="button"
                class="text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-lg px-2 py-2 text-center me-2 mb-2 mr-2"
              >
                Create Playlist +
              </button>
              <button
                onClick={(e) => handleCheckRequests(e)}
                type="button"
                class="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-lg px-2 py-2 text-center me-2 mb-2 mr-2"
              >
                Check Requests
              </button>

              {requestShow && (
                <div className="text-black">
                  <div className="flex flex-row items-center justify-center p-2">
                    <div className="text-sm font-black pr-2">
                      AUTO INSERT REQUESTS{" "}
                    </div>
                    <label class="relative inline-flex cursor-pointer items-center">
                      <input
                        id="switch-2"
                        type="checkbox"
                        class="peer sr-only"
                        checked={autoRequests}
                        onChange={handleAutoInsert}
                      />
                      <label for="switch-2" class="hidden"></label>
                      <div class="peer h-4 w-11 rounded-full border bg-slate-200 after:absolute after:-top-1 after:left-0 after:h-6 after:w-6 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-green-300 peer-checked:after:translate-x-full peer-focus:ring-green-300"></div>
                    </label>
                  </div>
                </div>
              )}
              <ul className="divide-y divide-gray-200">
                {requestShow &&
                  requstList &&
                  requstList.map((item, i) => (
                    <li
                      onClick={(e) => acceptRequest(e, item)}
                      className="flex items-center py-4 px-6 cursor-pointer"
                      key={i + 1}
                    >
                      <span className="text-gray-700 text-lg font-medium mr-4">
                        {i}.
                      </span>
                      <img
                        className="w-12 h-12 rounded-full object-cover mr-4"
                        src={item.request.coverart}
                        style={{ width: "24px", height: "24px" }}
                        alt={item.request.track}
                      />
                      <div className="flex-1">
                        <h3 className="text-lg font-medium text-gray-800">
                          {item.request.artist}
                        </h3>
                        <p className="text-gray-600 text-base">
                          {item.request.track}{" "}
                        </p>
                        <p className="text-gray-600 text-base border-t-2 border-gray-600">
                          <i>requested by</i> : {item.user}
                        </p>
                      </div>
                    </li>
                  ))}
                {!requestShow &&
                  jukebox &&
                  jukebox.map((item, i) => (
                    <li className="flex items-center py-4 px-6" key={i + 1}>
                      <span className="text-gray-700 text-lg font-medium mr-4">
                        {i}.
                      </span>
                      <img
                        className="w-12 h-12 rounded-full object-cover mr-4"
                        src={item.coverart}
                        style={{ width: "24px" }}
                        alt={item.track}
                      />
                      <div className="flex-1">
                        <h3 className="text-lg font-medium text-gray-800">
                          {item.artist}
                        </h3>
                        <p className="text-gray-600 text-base">{item.track} </p>
                        <p className="text-gray-600 text-base">{item.genre} </p>
                      </div>
                    </li>
                  ))}
              </ul>
              {createPlaylistName && (
                <div className="flex flex-row flex-grow w-full">
                  <div className="text-black flex-grow">
                    <input
                      id="new-playlist"
                      type="text"
                      placeholder="new playlist"
                      value={playlistName}
                      onChange={handlePlaylist}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") handleSubmitPlaylist(e);
                      }}
                      className="flex w-full text-gray-800 dark:text-gray-800 border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-8"
                    />
                  </div>
                  <div className="ml-2">
                    <button
                      onClick={(event) => handleSubmitPlaylist(event)}
                      className="flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 rounded-xl text-white px-4 py-1  w-12 h-12 mt-4 mr-2"
                    >
                      <span className="ml-0">
                        <svg
                          className="w-8 h-8 -mt-px text-white"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4 6C4 4.89543 4.89543 4 6 4H12H14.1716C14.702 4 15.2107 4.21071 15.5858 4.58579L19.4142 8.41421C19.7893 8.78929 20 9.29799 20 9.82843V12V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V6Z"
                            stroke="#ffffff"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8 4H13V7C13 7.55228 12.5523 8 12 8H9C8.44772 8 8 7.55228 8 7V4Z"
                            stroke="#ffffff"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M7 15C7 13.8954 7.89543 13 9 13H15C16.1046 13 17 13.8954 17 15V20H7V15Z"
                            stroke="#ffffff"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
              )}
              {showPlaylists && (
                <div className="flex flex-row flex-grow w-full">
                  <div className="text-black flex-grow">
                    playlists:
                    <ul>
                      {showPlaylists &&
                        playlists.map((item, i) => (
                          <li
                            className="flex flex-row items-center  text-left hover:bg-gray-100 rounded-xl p-2"
                            key={i}
                          >
                            <div className="flex-grow">{item.playlistName}</div>
                            <button
                              onClick={(e) =>
                                launchPlaylist(e, item, item.playlistName)
                              }
                              className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-lg px-2 py-2 text-center me-2 mb-2 mr-2 justify-self-end"
                            >
                              launch
                            </button>
                            <button
                              onClick={(e) => editPlaylist(e, item)}
                              className="text-black bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-yellow-300 dark:focus:ring-yellow-800 font-medium rounded-lg text-lg px-2 py-2 text-center me-2 mb-2 mr-2 justify-self-end"
                            >
                              edit
                            </button>
                            <button
                              onClick={(e) => deletePlaylist(e, item)}
                              className="text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-lg px-2 py-2 text-center me-2 mb-2 mr-2 justify-self-end"
                            >
                              delete
                            </button>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              )}
            </div>

            {showLoadingAni && (
              <div className="bg-white divide-y divide-gray-100 rounded-lg shadow-lg w-44 dark:bg-gray-700 muuz-confirm-panel mt-24">
                <LoadingAnimation />
              </div>
            )}

            {showDeleteConfirmed && (
              <div className="bg-white divide-y divide-gray-100 rounded-lg shadow-lg w-44 dark:bg-gray-700 muuz-delete-confirm-panel mt-24 text-black">
                <div className="flex justify-between items-center text-black">
                  <div></div>
                  <div></div>
                  <div></div>
                  <button
                    onClick={() => {
                      setShowDeleteConfirmed(!showDeleteConfirmed);
                    }}
                    className="block text-center flex justify-end px-4 py-2 text-black hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    <div>X</div>
                  </button>
                </div>
                Are you sure you want to delete this playlist
                <button
                  onClick={(e) => deletePlaylistConfirmed(e, deleteItem)}
                  className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-lg px-2 py-2 text-center me-2 mb-2 mr-2 justify-self-end"
                >
                  Yes
                </button>
                <button
                  onClick={() => {
                    setShowDeleteConfirmed(!showDeleteConfirmed);
                  }}
                  className="text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-lg px-2 py-2 text-center me-2 mb-2 mr-2 justify-self-end"
                >
                  No
                </button>
              </div>
            )}

            {settingsModal && (
              <>
                <div className="bg-white divide-y divide-gray-100 rounded-lg shadow-lg w-44 dark:bg-gray-700 muuz-confirm-panel">
                  <div className="flex justify-between text-white">
                    <div>&npsp;</div>
                    <div className="text-black font-black">
                      <h1>MUUZBOX CHANNEL SETTINGS</h1>
                    </div>
                    <button
                      onClick={() => {
                        setSettingsModal(!settingsModal);
                      }}
                      className="block text-center flex justify-end px-4 py-2 text-black hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      <div>X</div>
                    </button>
                  </div>
                  <Settings playlists={playlists} />
                </div>
              </>
            )}

            {editPlaylistModal && (
              <>
                <div className="bg-white divide-y divide-gray-100 rounded-lg shadow-lg w-44 dark:bg-gray-700 muuz-confirm-panel">
                  <div className="flex justify-between items-center text-white">
                    <div>
                      <NLMFileReader
                        handleFile={handleFile}
                        // insertLocalPlaylist={insertLocalPlaylist}
                        currentPlaylist={currentPlaylist}
                        playlistId={playlistId}
                      />
                    </div>
                    {/* <button
                      onClick={(e) => {
                        searchYoutube(e, acceptedSong);
                      }}
                      className="block text-center flex justify-end px-4 py-2 text-white bg-red-600 hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      <div className="flex items-center justify-center">
                        <svg
                          fill="#ffffff"
                          className="mr-1"
                          width="14px"
                          height="14px"
                          viewBox="0 0 16 16"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.027 9.92L16 13.95 14 16l-4.075-3.976A6.465 6.465 0 0 1 6.5 13C2.91 13 0 10.083 0 6.5 0 2.91 2.917 0 6.5 0 10.09 0 13 2.917 13 6.5a6.463 6.463 0 0 1-.973 3.42zM1.997 6.452c0 2.48 2.014 4.5 4.5 4.5 2.48 0 4.5-2.015 4.5-4.5 0-2.48-2.015-4.5-4.5-4.5-2.48 0-4.5 2.014-4.5 4.5z"
                            fill-rule="evenodd"
                          />
                        </svg>
                        <div>SEARCH MUUZBOX</div>
                      </div>
                    </button> */}
                    <div></div>
                    {/* <button
                      onClick={(e) => {
                        rejectRequest(e, acceptedSong);
                      }}
                      className="block text-center flex justify-end px-4 py-2 text-white bg-red-600 hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      <div>UPLOAD NLM</div>
                    </button> */}
                    <div></div>

                    <button
                      onClick={() => {
                        setEditPlaylistModal(!editPlaylistModal);
                      }}
                      className="block text-center flex justify-end px-4 py-2 text-black hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      <div>X</div>
                    </button>
                  </div>
                  <CurrentPlaylist
                    currentPlaylist={currentPlaylist}
                    local={local}
                    playlistId={playlistId}
                    updateCurrentPlaylist={updateCurrentPlaylist}
                  />
                </div>
              </>
            )}

            {responseModal && (
              <>
                <div className="bg-white divide-y divide-gray-100 rounded-lg shadow-lg w-44 dark:bg-gray-700 muuz-confirm-panel">
                  <div className="flex justify-between text-white">
                    <button
                      onClick={() => {
                        setRequesterListShow(!requesterListShow);
                      }}
                      className="block text-center flex justify-end px-4 py-2 text-white bg-green-600 hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      {requesterListShow ? (
                        <>
                          <div>MESSAGES</div>
                        </>
                      ) : (
                        <>
                          <div>REQUESTED BY</div>
                        </>
                      )}
                    </button>
                    <button
                      onClick={(e) => {
                        searchYoutube(e, acceptedSong);
                      }}
                      className="block text-center flex justify-end px-4 py-2 text-white bg-red-600 hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      <div className="flex items-center justify-center">
                        <svg
                          fill="#ffffff"
                          className="mr-1"
                          width="14px"
                          height="14px"
                          viewBox="0 0 16 16"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.027 9.92L16 13.95 14 16l-4.075-3.976A6.465 6.465 0 0 1 6.5 13C2.91 13 0 10.083 0 6.5 0 2.91 2.917 0 6.5 0 10.09 0 13 2.917 13 6.5a6.463 6.463 0 0 1-.973 3.42zM1.997 6.452c0 2.48 2.014 4.5 4.5 4.5 2.48 0 4.5-2.015 4.5-4.5 0-2.48-2.015-4.5-4.5-4.5-2.48 0-4.5 2.014-4.5 4.5z"
                            fill-rule="evenodd"
                          />
                        </svg>
                        <div>SEARCH MUUZBOX</div>
                      </div>
                    </button>
                    <button
                      onClick={(e) => {
                        rejectRequest(e, acceptedSong);
                      }}
                      className="block text-center flex justify-end px-4 py-2 text-white bg-red-600 hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      <div>REJECT</div>
                    </button>

                    <button
                      onClick={() => {
                        setResponseModal(!responseModal);
                      }}
                      className="block text-center flex justify-end px-4 py-2 text-black hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      <div>X</div>
                    </button>
                  </div>

                  <div className="text-center">
                    <div className="block w-full text-xl text-center text-red-800 h-8 text-ellipsis overflow-hidden">
                      {acceptedSong.request.track}
                    </div>
                    <div className="block w-full text-xl text-center text-black h-8 text-ellipsis overflow-hidden">
                      {acceptedSong.request.artist}
                    </div>
                    {showYoutubeSearch && (
                      <div className=" rounded-xl bg-slate-400 px-4 h-96 overflow-auto">
                        <MuuzTubeSearch acceptedSong={acceptedSong} vtp={vtp} />
                      </div>
                    )}
                  </div>

                  {!requesterListShow && !showYoutubeSearch && (
                    <>
                      <div
                        className="py-2 text-sm text-gray-700 dark:text-gray-200 chat-container"
                        aria-labelledby="dropdownDefaultButton"
                      >
                        <div
                          className="text-center overflow-y-auto overflow-x-auto"
                          ref={chatbase}
                        >
                          {cannedResponseShow &&
                            cannedResponse.map((item, i) => (
                              <div
                                className="flex flex-row items-center justify-between text-left hover:bg-gray-100 rounded-xl p-2"
                                key={i}
                                onClick={(e) => addCannedResponse(e, item)}
                              >
                                <div className="ml-2">{item.text}</div>
                                <div className="flex items-center justify-center h-8 w-8 rounded-full border overflow-hidden bg-indigo-500 flex-shrink-0">
                                  <button className="flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 rounded-xl text-white px-4 py-1 flex-shrink-0 w-4 h-4">
                                    <span className="ml-0">
                                      <svg
                                        className="w-4 h-4 transform rotate-45 -mt-px"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                                        ></path>
                                      </svg>
                                    </span>
                                  </button>
                                </div>
                              </div>
                            ))}

                          {messages.length > 0 &&
                            messages.map((message, index) => {
                              let noshow = true;
                              if (message.uid !== lastID) {
                                noshow = false;
                                lastID = message.uid;
                              }
                              return (
                                <div
                                  className={
                                    displayName === message.user ||
                                    email === message.user
                                      ? "col-start-2 col-end-13 p-3 rounded-lg chatbubble"
                                      : "col-start-1 col-end-12 p-3 rounded-lg chatbubble"
                                  }
                                  key={index}
                                >
                                  <div
                                    className={
                                      displayName === message.user ||
                                      email === message.user
                                        ? "flex items-center justify-start flex-row-reverse"
                                        : "flex flex-row items-center"
                                    }
                                  >
                                    {!noshow && (
                                      <div
                                        className={
                                          displayName === message.user ||
                                          email === message.user
                                            ? "flex items-center justify-center h-14 w-14 rounded-full border overflow-hidden bg-indigo-500 flex-shrink-0  z-10"
                                            : "flex items-center justify-center h-14 w-14 rounded-full border overflow-hidden bg-indigo-500 flex-shrink-0  z-10"
                                        }
                                      >
                                        <Avatar
                                          photoURL={message.photoURL}
                                          message={message}
                                        />
                                      </div>
                                    )}
                                    <div
                                      className={
                                        displayName === message.user ||
                                        email === message.user
                                          ? "relative mr-3 text-sm text-gray-800 dark:text-gray-800 bg-indigo-100 py-2 px-4 shadow rounded-l-xl rounded-br-xl z-10"
                                          : "relative ml-3 text-sm text-gray-800 dark:text-gray-800 bg-white py-2 px-4 shadow rounded-e-xl rounded-es-xl  z-10"
                                      }
                                    >
                                      <div>{message.text}</div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>

                      <div
                        className={`flex flex-row items-center rounded-xl bg-white w-full px-4 py-4 h-8 text-enter-fit requestChat text-enter-full chatbout-input `}
                      >
                        <div className="flex-grow ml-2 h-12">
                          <div className="relative w-full">
                            <input
                              id="response-chat"
                              type="text"
                              placeholder="response chat"
                              value={chatmessage}
                              onChange={handleChat}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") handleSubmit(e);
                              }}
                              className="flex w-full text-gray-800 dark:text-gray-800 border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-8"
                            />
                          </div>
                        </div>
                        <div className="ml-2">
                          <button
                            onClick={(event) => handleSubmit(event)}
                            className="flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 rounded-xl text-white px-4 py-1  w-12 h-12 mt-4 mr-2"
                          >
                            <span className="ml-0">
                              <svg
                                className="w-8 h-8 transform rotate-45 -mt-px"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                                ></path>
                              </svg>
                            </span>
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                  {requesterListShow && (
                    <>
                      <div className="text-black overflow-auto h-[280px]">
                        requester list
                        <p>{acceptedSong.user}</p>
                        <AccordionTrackList
                          data={requstList}
                          songId={acceptedSong.request.youTubeId}
                          setRequestedTrack={setRequestedTrackSetter}
                          pickTrack={pickTrack}
                          listTitle={"Grouped Tracks by Genre"}
                          mode={"light"}
                        />
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </section>
        </div>
      </section>
    </div>
  );
}

export default App;
